import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@api-new/authservice';
import { UserApiService } from '@api-new/userservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiErrorCode } from '../../../OLD_shared/enums/api-error-code.enum';
import { ErrorModel } from '../../../OLD_shared/models/app-state.model';
import { AuthService } from '../../services/auth.service';
import {
  agreeToTermsAndConditions,
  agreeToTermsAndConditionsFailure,
  agreeToTermsAndConditionsSuccess,
  askForTermsAndConditionsAction,
  authErrorAction,
  checkLoginMailAction,
  checkLoginMailActionFailure,
  checkLoginMailActionSuccess,
  loginAction,
  loginActionFailure,
  loginActionSuccess,
  loginMagicLinkAction,
  loginMagicLinkActionFailure,
  loginMagicLinkActionSuccess,
  loginSuccessAction,
  loginWithTokenAction,
  logoutAndReloadAction,
  navigateToVerifyCodeAction,
  navigateToVerifyCodeAndSendEmailAction,
  sendVerificationCodeAction,
  sendVerificationCodeErrorAction,
  sendVerificationCodeSuccessAction,
} from './auth.action';
import { selectRedirectUrl, selectSignInEmail } from './auth.selectors';

@Injectable()
export class AuthEffect {
  login = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction),
      switchMap(({ email, verificationCode, redirectUrl }) =>
        this.authApiService.HTTP_CP_SignIn_WithCode({ email, code: verificationCode }).pipe(
          map((signInResponse) => {
            return loginActionSuccess({
              signInResponse,
              link: redirectUrl,
            });
          }),
          catchError((error: ErrorModel) => of(loginActionFailure({ error, email }))),
        ),
      ),
    ),
  );

  loginOverMagicLink = createEffect(() =>
    this.actions$.pipe(
      ofType(loginMagicLinkAction),
      switchMap(({ email, verificationCode }) => {
        this.authService.logout();

        return this.authApiService.HTTP_CP_SignIn_WithCode({ email, code: verificationCode }).pipe(
          map((signInResponse) => {
            return loginMagicLinkActionSuccess({ signInResponse });
          }),
          catchError((error: ErrorModel) =>
            of(
              loginMagicLinkActionFailure({
                error,
                email,
                code: verificationCode,
              }),
            ),
          ),
        );
      }),
    ),
  );

  loginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccessAction, loginActionSuccess, loginMagicLinkActionSuccess),
        withLatestFrom(this.store.select(selectRedirectUrl)),
        tap(([{ signInResponse }, redirectUrl]) => {
          if (signInResponse) {
            this.authService.setAccessToken(signInResponse.accessToken, false);
          }
          redirectUrl ? this.router.navigateByUrl(redirectUrl) : void this.router.navigate([redirectUrl ?? `/${paths.PLATFORM}`]);
        }),
      ),
    { dispatch: false },
  );

  askForTermsAndConditionsAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(askForTermsAndConditionsAction),
        tap(() => this.router.navigate([`${paths.AUTH}/${paths.auth.TERMS_AND_CONDITIONS}`])),
      ),
    { dispatch: false },
  );

  loginWithToken = createEffect(() =>
    this.actions$.pipe(
      ofType(loginWithTokenAction),
      map(({ token }) => {
        this.authService.setAccessToken(token, true);

        return loginSuccessAction({
          signInResponse: { accessToken: null },
        });
      }),
      catchError((error) => of(loginActionFailure({ error, email: null }))),
    ),
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutAndReloadAction),
        switchMap(() => this.authApiService.HTTP_CP_SignOut()),
        tap(() => {
          this.authService.logout();
          this.router.navigate([``]).then(() => {
            requestAnimationFrame(() => window.location.reload());
          });
        }),
      ),
    { dispatch: false },
  );

  checkMailBeforeSignIn = createEffect(() =>
    this.actions$.pipe(
      ofType(checkLoginMailAction),
      switchMap(({ email }) =>
        this.userApiService.HTTP_CP_DoesEmailExist({ email }).pipe(
          map(({ doesEmailExist }) => checkLoginMailActionSuccess({ doesEmailExist })),
          catchError((error) => of(checkLoginMailActionFailure({ error }))),
        ),
      ),
    ),
  );

  checkLoginMailSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkLoginMailActionSuccess),
        tap(({ doesEmailExist }) => {
          if (doesEmailExist) {
            this.store.dispatch(navigateToVerifyCodeAndSendEmailAction());
          }
        }),
      ),
    { dispatch: false },
  );

  navigateToVerifyCodeAndSendEmail = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToVerifyCodeAndSendEmailAction),
        withLatestFrom(this.store.select(selectSignInEmail)),
        map(([_, email]) => {
          void this.router.navigate([paths.AUTH, paths.auth.LOGIN_VERIFICATION]).then((didNavigate) => {
            if (didNavigate && !!email) {
              this.store.dispatch(sendVerificationCodeAction({ email }));
            }
          });
        }),
      ),
    { dispatch: false },
  );

  navigateToVerifyCode = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToVerifyCodeAction),
        map(() => {
          void this.router.navigate([paths.AUTH, paths.auth.LOGIN_VERIFICATION]);
        }),
      ),
    { dispatch: false },
  );

  sendVerificationCode = createEffect(() =>
    this.actions$.pipe(
      ofType(sendVerificationCodeAction),
      switchMap(({ email }) =>
        this.authApiService.HTTP_CP_ProvideClientUserSignInCode({ email }).pipe(
          map(() => sendVerificationCodeSuccessAction()),
          catchError((error) => of(sendVerificationCodeErrorAction({ error }))),
        ),
      ),
    ),
  );

  loginSuccessActionWithAgreement = createEffect(() =>
    this.actions$.pipe(
      ofType(agreeToTermsAndConditions),
      switchMap(() =>
        this.userApiService.HTTP_CP_AcceptTermsAndConditions().pipe(
          map(() => agreeToTermsAndConditionsSuccess()),
          catchError((error) => of(agreeToTermsAndConditionsFailure({ error }))),
        ),
      ),
    ),
  );

  agreeToTermsAndConditionsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(agreeToTermsAndConditionsSuccess),
        withLatestFrom(this.store.select(selectRedirectUrl)),
        tap(([_, redirectUrl]) => (redirectUrl ? this.router.navigateByUrl(redirectUrl) : this.router.navigate([`/${paths.PLATFORM}`]))),
      ),
    { dispatch: false },
  );

  loginFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActionFailure, loginMagicLinkActionFailure),
      map(({ error }: { type: string; error: ErrorModel }) => {
        return authErrorAction({ authErrorCode: error.error.message as ApiErrorCode });
      }),
    ),
  );

  loginMagicLinkFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(loginMagicLinkActionFailure),
      map(({ error, code }) => {
        if (error.error.message === ApiErrorCode.SIGN_IN_CODE_EXPIRED && code.length > 6) {
          return navigateToVerifyCodeAndSendEmailAction();
        }
        return navigateToVerifyCodeAction();
      }),
    ),
  );

  loginExpiredFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActionFailure),
      map(({ error, email }: { error: ErrorModel; email: string }) => {
        if (error.error.message === ApiErrorCode.SIGN_IN_CODE_EXPIRED) {
          return sendVerificationCodeAction({ email });
        } else {
          return authErrorAction({ authErrorCode: error.error.message as ApiErrorCode });
        }
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly authApiService: AuthApiService,
    private readonly userApiService: UserApiService,
  ) {}
}
