import { ApiErrorCode } from '@app/OLD_shared/enums/api-error-code.enum';
import { AppState } from '@app/OLD_shared/models/app-state.model';
import { createSelector } from '@ngrx/store';
import { SignInType } from '@shared/store/auth/auth.reducer';
import { AuthState } from './auth.state';

export const selectAuthState = (state: AppState): AuthState => state.auth;

export const selectAuthLoading = createSelector(selectAuthState, (state: AuthState) => (state != null ? state.loading : false));

export const selectIsLoginFromParamsLoading = createSelector(selectAuthState, (state: AuthState) =>
  state != null ? state.auth.isLoginFromParamsLoading : false,
);

export const selectIsAdviserLogInError = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.auth.authErrorCode === ApiErrorCode.ADVISOR_SIGN_IN_INTO_CLIENT_PORTAL,
);

export const selectRedirectUrl = createSelector(selectAuthState, (state: AuthState): string => state.auth.redirectUrl);

export const selectSignInEmail = createSelector(selectAuthState, (state: AuthState): string => state.auth.email);

export const selectLastTimeVerificationCodeSent = createSelector(
  selectAuthState,
  (state: AuthState): Date => state.auth.lastTimeVerificationCodeSent,
);

export const selectNoEmailFoundError = createSelector(selectAuthState, (state: AuthState): boolean => state.auth.mailStatus === false);

export const signInErrorCode = createSelector(selectAuthState, (state: AuthState): string => state.auth.authErrorCode);

export const isInvalidSignInCode = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.auth.authErrorCode === ApiErrorCode.SIGN_IN_CODE_INVALID,
);

export const isExpiredSignInCode = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.auth.authErrorCode === ApiErrorCode.SIGN_IN_CODE_EXPIRED,
);

export const selectLastSignInCode = createSelector(selectAuthState, (state: AuthState): string => state.auth.lastSignInCode);

export const selectIsSendingVerificationCode = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.auth.isSendingVerificationCode,
);

export const selectLastSignInType = createSelector(selectAuthState, (state: AuthState): SignInType => state.auth.lastSignInType);
