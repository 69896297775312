export enum MortgageTypeEnum {
  ANY_MORTGAGE = 0,
  RESIDENTIAL = 1,
  BTL = 2,
}

export enum TenureTypeEnum {
  FREEHOLD = 0,
  LEASEHOLD = 1,
}

export enum PurchaseTypeEnum {
  STANDARD_RESIDENTIAL = 0,
  BUY_TO_LET = 1,
  LET_TO_BUY = 2,
  SHARED_OWNERSHIP = 3,
  RIGHT_TO_BUY = 4,
}

export enum PurposeEnum {
  PURPOSE_ANY = 0,
  REMORTGAGE = 1,
  FTB = 2,
  HOUSE_MOVE = 3,
  UNKNOWN = 10,
}

export enum PaymentOptionsEnum {
  CAPITAL_REPAYMENT = 0,
  INTEREST_ONLY = 1,
  PART_AND_PART = 2,
}
