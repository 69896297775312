import { ENUM_ManualPropertyValueType, ENUM_MortgagePartEarlyRepaymentChargeType, ENUM_MortgageSavingMethod } from '@api-new/common';
import { HTTP_CP_MortgageComparisonSettings } from '@api-new/mortgageservice';
import { HTTP_CP_CreateProperty_Request } from '@api-new/propertyservice';
import { HTTP_CP_ClientUser } from '@api-new/userservice';
import moment from 'moment';
import { DEFAULT_COMPARISON_PERIOD } from './common';
import { DocumentTypeEnum } from './enums/document.enum';
import { PurposeEnum } from './enums/mortgage-search.enum';
import { DocumentModel } from './models/document.model';
import { MortgageErcModel } from './models/mortgage-erc.model';
import { MortgagePartModel } from './models/mortgage-part.model';
import { MortgageSearchCriteriaMetadataModel } from './models/mortgage-search.model';
import { MortgageModel } from './models/mortgage.model';
import { OverpaymentModel } from './models/overpayment.model';
import { ProductSearchFilterModel, SmartSearchTypeEnum } from './models/smart-search.model';

export const DEFAULTS = {
  defaultUser: (): HTTP_CP_ClientUser => ({
    residentialAddressLines: null,
    id: null,
    advisorUserId: null,
    advisorUserFullName: null,
    companyId: null,
    affiliateId: null,
    email: null,
    phoneNumber: null,
    title: null,
    firstName: null,
    middleName: null,
    lastName: null,
    fullName: null,
    dateOfBirth: null,
    hasBadCreditDeclaredBankruptcy: null,
    hasBadCreditIndividualVoluntaryArrangement: null,
    hasBadCreditCountyCourtJudgment: null,
    hasBadCreditUnsecuredLoan: null,
    hasBadCreditPropertyRepossessed: null,
    creationMethod: null,
    tutorialCleared: null,
    clientUserEmployment: null,
    hasAcceptedTermsAndConditions: null,
    showDeclaredPropertyBanner: null,
  }),

  fillDefaultUser: (user: HTTP_CP_ClientUser): HTTP_CP_ClientUser => ({
    ...DEFAULTS.defaultUser(),
    ...user,
  }),

  defaultNewProperty: (): HTTP_CP_CreateProperty_Request => ({
    addressId: null,
    investmentType: null,
    propertyValues: [
      {
        year: null,
        month: null,
        manualValue: null,
        manualPropertyValueType: ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_UNSPECIFIED,
      },
    ],
    existingResidentialPropertyChange: {
      changeToClosed: null,
      changeToBuyToLet: null,
    },
    additionalPropertyData: {
      propertyType: null,
      constructionYear: null,
      numberOfBedrooms: null,
      ownershipShare: null,
      tenure: null,
      // TODO
      isCompanyOwned: false,
      companyOwnedCompanyRegistrationNumber: undefined,
      companyOwnedCompanyRegisteredName: undefined,
    },
  }),

  defaultMortgage: (): MortgageModel => {
    const defaultCriteria: HTTP_CP_MortgageComparisonSettings = DEFAULTS.defaultMortgageSearch();
    return {
      lenderId: null,
      roughCurrentMonthlyPayment: true,
      outstandingBalanceDate: null,
      mortgageParts: [DEFAULTS.defaultMortgagePart()],
      mortgageComparisonSettings: {
        ...defaultCriteria,
        showFixed: true,
        showStandard: true,
        showStepped: true,
      },
    };
  },

  fillDefaultMortgage: (newMortgage: MortgageModel): MortgageModel => {
    const defaultMortgage: MortgageModel = DEFAULTS.defaultMortgage();
    return {
      ...defaultMortgage,
      ...newMortgage,
      mortgageComparisonSettings: DEFAULTS.fillDefaultMortgageSearch(newMortgage.mortgageComparisonSettings),
    };
  },

  defaultMortgagePart: (): MortgagePartModel => ({
    repaymentType: null,
    initialPeriod: null,
    roughInitialAmount: true,
    mortgagePartOverpayments: [],
    mortgagePartUnderpayments: [],
    mortgagePartSteps: [
      {
        mortgagePartStepType: null,
        fixedInterestRate: null,
      },
    ],
  }),

  defaultMortgageSearch: (): any => ({
    excludedLenderIds: [],
    savingMethod: ENUM_MortgageSavingMethod.MORTGAGE_SAVING_METHOD_TOTAL_SAVINGS,
    preferredComparisonPeriod: DEFAULT_COMPARISON_PERIOD,
  }),

  fillDefaultMortgageSearch: (newCriteria: HTTP_CP_MortgageComparisonSettings): any => {
    const defaultCriteria: Partial<HTTP_CP_MortgageComparisonSettings> = DEFAULTS.defaultMortgageSearch();
    if (newCriteria == null) {
      return defaultCriteria;
    }
    return {
      ...defaultCriteria,
      ...newCriteria,
      maxInitialPayments:
        newCriteria.maxInitialPayments != null
          ? Math.round(newCriteria.maxInitialPayments <= 0 ? 0 : newCriteria.maxInitialPayments)
          : null,
      savingMethod: newCriteria.savingMethod ?? ENUM_MortgageSavingMethod.MORTGAGE_SAVING_METHOD_TOTAL_SAVINGS,
    };
  },

  defaultDocument: (): DocumentModel => ({
    name: null,
    resourceId: null,
    type: DocumentTypeEnum.DOC_TYPE_UNDEFINED,
    clientUserId: '',
    files: [],
  }),

  defaultSearchPreferencesMetadata: (): MortgageSearchCriteriaMetadataModel => ({
    selectedIndex: 0,
    restrictBanks: false,
    minimumSavingsForMortgageExpiryTypeEarly: false,
    maxFees: false,
    maxRate: false,
    maxInitialPayments: false,
    minRatePeriod: false,
    maxRatePeriod: false,
  }),

  defaultExtraPayments: (): OverpaymentModel => ({
    id: null,
    startDate: null,
    endDate: null,
    mortgagePartOverpaymentType: null,
    amount: null,
  }),

  defaultProductSearchFilters: (): ProductSearchFilterModel => ({
    name: 'Search',
    type: SmartSearchTypeEnum.IMAGINE_SEARCH,
    purpose: PurposeEnum.UNKNOWN,
    propertyValue: 0,
    mortgageAmount: 0,
    duration: 0,
    property: null,
    preferences: {},
    mortgagePreferences: {},
  }),

  defaultErc: (): MortgageErcModel => ({
    amount: 0,
    mortgagePartEarlyRepaymentChargeType: ENUM_MortgagePartEarlyRepaymentChargeType.MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_FIXED_AMOUNT,
    startDate: moment(),
    endDate: moment(),
  }),
};
