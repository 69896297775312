import { HTTP_CP_Property } from '@api-new/propertyservice';
import { Moment } from 'moment';
import { MortgageTypeEnum, PaymentOptionsEnum, PurposeEnum, TenureTypeEnum } from '../enums/mortgage-search.enum';
import { BaseModel } from './base.model';
import { MortgagePreferences, UserPreferences } from './mortgage-search.model';
import { SearchResponseModel } from './search-response.model';

export type LoanIncreaseReason = 'home_improvements' | 'car_purchase' | 'investment' | 'debt_consolidation' | 'new_mortgage';

export interface SmartSearchResponseModel {
  propertySearches: PropertySearchModel[];
  imagineSearches: ImagineSearchModel[];
}

export interface PropertySearchModel extends BaseModel {
  fkUser?: string;
  fkProperty?: string;
  mortgageAmount?: number;
  loanIncreaseReason?: LoanIncreaseReason;
  preferences?: UserPreferences;
  mortgagePreferences?: MortgagePreferences;
  purpose?: PurposeEnum;
  duration?: number; // months
  name?: string;
  type?: SmartSearchTypeEnum;

  inProgress?: boolean;
  resultsCount?: number;
  bestMonthlyPayment?: number;
}

export interface ImagineSearchModel extends BaseModel {
  preferences?: UserPreferences;
  mortgagePreferences?: MortgagePreferences;
  purpose?: PurposeEnum;
  maxErcLookahead?: number;

  name?: string;
  duration?: number; // months
  deposit?: number;
  mortgageType?: MortgageTypeEnum;
  propertyPrice?: number;
  tenure?: TenureTypeEnum;
  paymentOptions?: PaymentOptionsEnum;

  inProgress?: boolean;
  resultsCount?: number;
  bestMonthlyPayment?: number;
}

export interface SmartSearchModel extends PropertySearchModel, ImagineSearchModel {}

// FE only

export interface ProductSearchFilterModel {
  id?: string;
  type: SmartSearchTypeEnum;
  name: string;
  property: HTTP_CP_Property;
  propertyValue: number;
  mortgageAmount: number;
  duration: number;
  purpose?: PurposeEnum;
  preferences: UserPreferences;
  mortgagePreferences: MortgagePreferences;
}

export enum SmartSearchTypeEnum {
  IMAGINE_SEARCH = 'imagineSearch',
  PROPERTY_SEARCH = 'propertySearch',
}

export interface SmartSearchStoreModel {
  smartSearch: SmartSearchModel;
  type: SmartSearchTypeEnum;
  searchResult?: SearchResponseModel;
}

export interface SmartSearchProductRow {
  id: string;
  name: string;
  type: string;
  createdAt: Moment;
  mortgageAmount: number;
  mortgageLengthYears: number;
  bestMonthlyPayment: number;
  resultsCount: number;
  fkApplication: string;
  isLoading: boolean;
}

export interface SmartSearchMetadata {
  searchResultLoaded: {
    [prop: string]: boolean;
  };
  activeSearchResultId: string;
  searchDeleteLoading: boolean;
}
