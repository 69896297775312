<main>
  <section class="maintenance">
    <header>
      <h1 class="maintenance__title">
        Sorry, we're down for a bit of planned maintenance right now.
      </h1>
    </header>

    <div class="maintenance__body">
      <p>
        We'll be back up and running as fast as possible, making millions of calculations to compare
        your unique mortgage to hundreds of deals on the market.
      </p>
      <p>
        We expect to be scouring the market again from:
        <br />
        <strong>{{ maintenanceDate$ | async }}</strong>
        <!--keep this date format ^^-->
      </p>
      <!--<p>-->
      <!--In the meantime, reach us on:-->
      <!--<br>-->
      <!--<a href="https://dashly.com" target="_blank"></a>-->
      <!--<br>-->
      <!--<a href="https://www.facebook.com/DashlyHQ" target="_blank">Facebook</a>-->
      <!--<br>-->
      <!--<a href="https://twitter.com/DashlyHQ" target="_blank">Twitter</a>-->
      <!--</p>-->
    </div>
  </section>
</main>
