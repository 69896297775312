import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BasicComponentsModule } from './basic-components/basic-components.module';
import { ErrorHandlerModule } from './error-handler/error-handler.module';

@NgModule({
  imports: [CommonModule, BasicComponentsModule, ErrorHandlerModule, GoogleMapsModule],
  exports: [BasicComponentsModule],
})
export class SharedModule {}
