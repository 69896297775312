import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { adviserReducer } from '@shared/store/adviser/adviser.reducer';
import { authReducer } from '@shared/store/auth/auth.reducer';
import { bestBetterDealsReducer } from '@shared/store/best-better-deals/best-better-deals.reducer';
import { betterDealsReducer } from '@shared/store/better-deal/better-deal.reducer';
import { clientUserReducer } from '@shared/store/client-user/client-user.reducer';
import { helpCasesReducer } from '@shared/store/help-cases/help-cases.reducer';
import { lendersReducer } from '@shared/store/lender/lenders.reducer';
import { mortgageCasesReducer } from '@shared/store/mortgage-cases/mortgage-cases.reducer';
import { mortgagesReducer } from '@shared/store/mortgages/mortgages.reducer';
import { propertiesReducer } from '@shared/store/properties/properties.reducer';
import { smartSearchCasesReducer } from '@shared/store/smart-search-cases/smart-search-cases.reducer';
import { smartSearchDealsReducer } from '@shared/store/smart-search-deals/smart-search-deals.reducer';
import { smartSearchesReducer } from '@shared/store/smart-searches/smart-searches.reducer';
import { AppState } from '../../models/app-state.model';
import { affiliateReducer } from '../affiliate/affiliate.reducer';
import { companyReducer } from '../company/company.reducer';
import { mortgageCaseReducer } from '../mortgage-case/mortgage-case.reducer';
import { notificationSettingsReducer } from '../notification-settings/notification-settings.reducer';
import { propertyReducer } from '../property/property.reducer';
import { calendarEventReducer } from './calendar-event.reducer';
import { lenderReducer } from './lender.reducer';
import { mortgageEditReducer } from './mortgage-edit.reducer';
import { mortgageReducer } from './mortgage.reducer';
import { remoteConfigReducer } from './remote-config.reducer';
import { savingChartReducer } from './saving-chart.reducer';

export const reducers: ActionReducerMap<AppState> = {
  adviser: adviserReducer,
  bestBetterDeals: bestBetterDealsReducer,
  betterDeals: betterDealsReducer,
  lenders: lendersReducer,
  mortgageCases: mortgageCasesReducer,
  mortgages: mortgagesReducer,
  properties: propertiesReducer,
  smartSearchCases: smartSearchCasesReducer,
  smartSearchDeals: smartSearchDealsReducer,
  smartSearches: smartSearchesReducer,
  clientUser: clientUserReducer,
  helpCases: helpCasesReducer,

  // OLD REDUCERS
  auth: authReducer,
  OLD_affiliate: affiliateReducer,
  OLD_calendarEvents: calendarEventReducer,
  OLD_company: companyReducer,
  OLD_lender: lenderReducer,
  OLD_mortgage: mortgageReducer,
  OLD_mortgageCase: mortgageCaseReducer,
  OLD_mortgageEdit: mortgageEditReducer,
  OLD_notificationSettings: notificationSettingsReducer,
  OLD_property: propertyReducer,
  OLD_remoteConfig: remoteConfigReducer,
  OLD_route: routerReducer,
  OLD_savingChart: savingChartReducer,
};
