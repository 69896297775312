import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { HELP_LINK } from '../../common';
import { OpenHelpPageAction } from '../actions/helpdesk.action';

@Injectable()
export class HelpdeskEffect {
  openHelpPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpenHelpPageAction.type),
        tap(() => {
          window.open(HELP_LINK);
          return;
        }),
      ),
    { dispatch: false },
  );

  constructor(private readonly actions$: Actions) {}
}
