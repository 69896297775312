import { RemoteConfigState } from '../../models/app-state.model';
import { RemoteConfigAction, RemoteConfigErrorAction, SetRemoteConfigAction } from '../actions/remote-config.action';

const initialState: RemoteConfigState = {
  entity: null,
  errors: null,
  loading: false,
};

export function remoteConfigReducer(state: RemoteConfigState = initialState, action: RemoteConfigAction): RemoteConfigState {
  switch (action.type) {
    case SetRemoteConfigAction.type: {
      const config = action.payload as any;
      return (
        config && {
          ...state,
          entity: {
            ...config,
            maintenanceMode: window.location.origin.includes('localhost')
              ? { clientPortal: { enabled: false, date: '' } }
              : config.maintenanceMode,
          },
          loading: false,
        }
      );
    }

    case RemoteConfigErrorAction.type:
      return {
        ...state,
        entity: null,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
}
