import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { paths } from '@platform/paths';
import { AuthService } from '@shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorModel } from '../models/app-state.model';
import { ToastService } from './toast.service';

@Injectable()
export class ErrorService {
  private readonly errorMessages = new BehaviorSubject<ErrorModel[]>([]);
  readonly errorMessages$ = this.errorMessages.asObservable();
  readonly errorsShown$ = this.errorMessages.pipe(map((errors) => errors.length > 0));

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly toastService: ToastService,
  ) {}

  // TODO - there is possibility to remove pushError and put this to the dashly-error-interceptor.ts
  pushError(error: ErrorModel): void {
    if (error.status === 401) {
      this.authService.logout();
      this.toastService.showInfo({ text: 'You have been logged out due to inactivity' });
      void this.router.navigateByUrl('/');
      return;
    }

    console.error(error);
    this.errorMessages.next([...this.errorMessages.getValue(), error]);
    this.router.navigate([paths.PLATFORM, paths.platform.ERROR]);
  }

  hideError(): void {
    this.errorMessages.next([]);
  }
}
