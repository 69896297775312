<div class="landing-container">
  @if (deleteLoading$ | async) {
    <div class="landing-loading solid">
      <div class="dashly-loading-container">
        <img alt="loading" src="../../../assets/OLD_images/loading.gif" />
      </div>
    </div>
  }
  <div class="landing-body">
    <div class="landing-logo-container">
      <div class="landing-third-party-logo-container-wrapper">
        <div
          class="landing-third-party-logo-container"
          [ngClass]="{ 'logo-displayed': !logoLoading }">
          @if (relevantLandingScreenLogo$ | async; as logo) {
            <img
              class="landing-third-party-logo"
              [src]="logo"
              alt="company-logo"
              (load)="setLogoLoading(false)" />
          }
          <div class="landing-third-party-logo-subtitle">Powered by Dashly</div>
        </div>
      </div>

      <div (click)="logout()" class="back-button">Go back</div>
    </div>

    @if (deletionState === DeletionState.DELETED) {
      <div class="landing-title">
        <span class="bold">Your account has been deleted</span>
      </div>
      <div class="landing-subtitle">
        It’s sad you want to leave Dashly. But, as a no obligation platform, you’re free to do
        whatever you want.
      </div>
    } @else {
      <div class="landing-title">
        <span class="bold">Invalid deletion request</span>
      </div>
      <div class="landing-subtitle">
        The link you provided has either expired or is invalid. Try resending the deletion request.
      </div>
    }

    <div class="landing-support">
      If we did something wrong, or you’d like to provide us with some feedback, please visit our
      <a class="landing-support-link" href="https://help.dashly.com/kb-tickets/new" target="_blank"
        >support&nbsp;page</a
      >.
    </div>

    <div class="landing-image">
      <img
        alt="landing-house-image"
        class="landing-house-image"
        src="../../../assets/OLD_images/onboarding-05.jpg" />
    </div>
  </div>
</div>
