import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CaseApiService } from '@api-new/caseservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { paths } from '@platform/paths';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../models/app-state.model';
import { ErrorService } from '../../services/error.service';
import { ToastService } from '../../services/toast.service';
import { $newMortgageCaseData } from '../selectors/accounts-view.selectors';
import {
  closeMortgageCase,
  closeMortgageCaseFailure,
  closeMortgageCaseSuccess,
  createMortgageCase,
  createMortgageCaseFailure,
  createMortgageCaseSuccess,
  getMortgageCasesFailure,
  getMortgageCasesSuccess,
  getOldMortgageCases,
  prepareNewMortgageCase,
  updateNewMortgageCase,
} from './mortgage-case.action';

@Injectable()
export class MortgageCaseEffect {
  getMortgageCases = createEffect(() =>
    this.actions$.pipe(
      ofType(getOldMortgageCases),
      switchMap(() =>
        this.caseApiService.HTTP_CP_ListMortgageCases().pipe(
          map(({ mortgageCases }) => getMortgageCasesSuccess({ mortgageCases: mortgageCases || [] })),
          catchError((error) => {
            this.errorService.pushError(error);
            return of(getMortgageCasesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  createMortgageCase = createEffect(() =>
    this.actions$.pipe(
      ofType(createMortgageCase),
      switchMap(({ request }) =>
        this.caseApiService.HTTP_CP_UpdateMortgageCase(request).pipe(
          map(() => {
            this.toastService.showSuccess({ headLine: 'Success', text: 'Form was successfully submitted' });
            void this.router.navigate([
              paths.PLATFORM,
              paths.platform.MORTGAGES,
              paths.platform.mortgages.LIST,
              paths.platform.mortgages.list.MORTGAGE_CASE_HISTORY,
            ]);
            return createMortgageCaseSuccess();
          }),
          catchError((error) => {
            this.toastService.showError({ headLine: 'Create', text: 'Remortgage failed to create' });
            return of(createMortgageCaseFailure({ error }));
          }),
        ),
      ),
    ),
  );

  onCreateMortgageCaseSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(createMortgageCaseSuccess),
      map(() => getOldMortgageCases()),
    ),
  );

  closeMortgageCase = createEffect(() =>
    this.actions$.pipe(
      ofType(closeMortgageCase),
      switchMap(({ mortgageCaseCloseRequest }) =>
        this.caseApiService.HTTP_CP_UpdateMortgageCase(mortgageCaseCloseRequest).pipe(
          map(() => {
            this.toastService.showSuccess({ headLine: 'Close', text: 'Remortgage was successfully closed' });
            return closeMortgageCaseSuccess({ mortgageCaseId: mortgageCaseCloseRequest.mortgageCaseId });
          }),
          catchError((error) => {
            this.toastService.showError({ headLine: 'Close', text: 'Remortgage failed to close' });
            return of(closeMortgageCaseFailure({ error }));
          }),
        ),
      ),
    ),
  );

  prepareApplication = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareNewMortgageCase),
      withLatestFrom(this.store.pipe(select($newMortgageCaseData))),
      switchMap(([_, data]) => {
        if (data == null) {
          this.toastService.showError({
            headLine: 'Application creation',
            text: 'Unable to start application process',
          });
          return of(null);
        }
        void this.router.navigate([
          paths.PLATFORM,
          paths.platform.MORTGAGES,
          paths.platform.mortgages.MORTGAGE_CASE_ENQUIRY,
          paths.platform.mortgages.mortgage_case_enquiry.START,
        ]);
        return of(updateNewMortgageCase(data));
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly errorService: ErrorService,
    private readonly toastService: ToastService,
    private readonly caseApiService: CaseApiService,
  ) {}
}
