import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@shared/services/auth.service';
import { logoutAndReloadAction } from '@shared/store/auth/auth.action';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppState } from './models/app-state.model';

@Injectable()
export class DashlyHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store<AppState>,
    private readonly authService: AuthService,
  ) {}

  intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // assets needs to use local in develop
    if (req.url.search('assets') >= 0 || req.url.startsWith('https://storage.googleapis.com')) {
      return next.handle(req);
    }

    const token = this.authService.token();

    if (token == null) {
      return next.handle(req.clone());
    }

    if (!this.authService.tokenValid()) {
      this.store.dispatch(logoutAndReloadAction());
      return;
    }

    switch (req.method) {
      case 'GET':
        return next
          .handle(
            req.clone({
              headers: req.headers.set('Authorization', `Bearer ${token}`),
            }),
          )
          .pipe(logoutOnAuthenticationFail(this.authService));
      case 'POST':
      case 'DELETE':
      case 'PUT':
      case 'PATCH':
        return next
          .handle(
            req.clone({
              headers: req.headers.set('Authorization', `Bearer ${token}`),
              body: {
                ...req.body,
              },
            }),
          )
          .pipe(logoutOnAuthenticationFail(this.authService));

      default:
        return next
          .handle(
            req.clone({
              headers: req.headers.set('Authorization', `Bearer ${token}`),
            }),
          )
          .pipe(logoutOnAuthenticationFail(this.authService));
    }
  }
}

function logoutOnAuthenticationFail(authService: AuthService): OperatorFunction<HttpEvent<unknown>, HttpEvent<unknown>> {
  return catchError((error: HttpErrorResponse) => {
    if (error && error.status == HttpStatusCode.Unauthorized) {
      authService.logoutAndReload();
    }

    return throwError(error);
  });
}
