import { Injectable } from '@angular/core';
import { ENUM_MortgageClosedReason } from '@api-new/common';
import { MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { dateToISOStringWithoutTimezone } from '@shared-lib/utils/date-utils/dateToISOStringWithoutTimezone';
import { getBetterDeals } from '@shared/store/better-deal/better-deal.actions';
import { loadInitialPlatformData } from '@shared/store/common.actions';
import {
  closeMortgage,
  closeMortgageSuccess,
  getMortgages,
  getMortgagesFailure,
  getMortgagesSuccess,
  updateMortgageSuccess,
} from '@shared/store/mortgages/mortgages.actions';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MortgagesEffects {
  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getMortgages)));

  getMortgages = createEffect(() =>
    this.actions$.pipe(
      ofType(getMortgages),
      switchMap(() => this.mortgageApiService.HTTP_CP_ListMortgages()),
      map((mortgages) => getMortgagesSuccess(mortgages)),
      catchError((error) => of(getMortgagesFailure({ error }))),
    ),
  );

  closeMortgage = createEffect(() =>
    this.actions$.pipe(
      ofType(closeMortgage),
      switchMap(({ mortgageId, closeDated }) =>
        this.mortgageApiService
          .HTTP_CP_CloseMortgage({
            mortgageId,
            reason: ENUM_MortgageClosedReason.MORTGAGE_CLOSED_REASON_PAID_OFF,
            when: dateToISOStringWithoutTimezone(closeDated),
          })
          .pipe(
            map(() => closeMortgageSuccess({ mortgageId })),
            catchError((error) => of(getMortgagesFailure({ error }))),
          ),
      ),
    ),
  );

  handleUpdateMortgageSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMortgageSuccess),
      map(({ mortgage }) => getBetterDeals({ mortgageId: mortgage.id })),
    ),
  );

  constructor(
    private readonly mortgageApiService: MortgageApiService,
    private readonly actions$: Actions,
  ) {}
}
