export enum MimeTypeEnum {
  MIME_TYPE_UNDEFINED = 'MIME_TYPE_UNDEFINED',
  MIME_TYPE_MSWORD = 'MIME_TYPE_MSWORD',
  MIME_TYPE_PDF = 'MIME_TYPE_PDF',
  MIME_TYPE_EXCEL = 'MIME_TYPE_EXCEL',
  MIME_TYPE_XEXCEL = 'MIME_TYPE_XEXCEL',
  MIME_TYPE_JPEG = 'MIME_TYPE_JPEG',
  MIME_TYPE_PJPEG = 'MIME_TYPE_PJPEG',
  MIME_TYPE_PNG = 'MIME_TYPE_PNG',
  MIME_TYPE_GIF = 'MIME_TYPE_GIF',
}

export enum DocumentTypeEnum {
  DOC_TYPE_UNDEFINED = 'DOC_TYPE_UNDEFINED',
  DOC_TYPE_MORTGAGE = 'DOC_TYPE_MORTGAGE',
  DOC_TYPE_LOAN = 'DOC_TYPE_LOAN',
  DOC_TYPE_PERSONAL = 'DOC_TYPE_PERSONAL',
  DOC_TYPE_PROPERTY = 'DOC_TYPE_PROPERTY',
  DOC_TYPE_INSURANCE = 'DOC_TYPE_INSURANCE',
  DOC_TYPE_ASSET = 'DOC_TYPE_ASSET',
}

export enum SignedUrlRequestPurposeEnum {
  UNDEFINED = 'UNDEFINED',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
}
