<div class="forms">
  <div class="form-container">
    <div class="landing-logo-container">
      <a href="https://www.dashly.com/" target="_blank">
        <img class="landing-logo" src="assets/images/mortgage-monitor-blue.png" />
      </a>
    </div>
    <form [formGroup]="emailForm" class="login-form">
      <span class="landing-title"> Welcome to <span class="bold">Mortgage Monitor</span> </span>
      <span class="landing-subtitle">Please enter your email address to continue</span>
      <div class="inputs">
        <div class="landing-responsive-row">
          <app-input
            class="landing-input"
            formControlName="email"
            name="login-email"
            type="email"
            inputId="login-email"
            [fsBlock]="true"
            [warnState]="showWarnState('email') || (noEmailFoundError$ | async)"
            label="E-mail" />
        </div>
        <div class="dashly-warn-text-container">
          @if (showWarnState("email")) {
            <span class="dashly-warn-text">Invalid email</span>
          }
          @if (noEmailFoundError$ | async) {
            <span class="dashly-warn-text">There is no user account with this e-mail address</span>
          }
        </div>
        <div class="login-button-container">
          <app-arrow-button
            (click)="login()"
            [disabled]="emailForm.invalid"
            [loading]="loading$ | async">
            Continue
          </app-arrow-button>
        </div>
      </div>
    </form>
  </div>
  <div class="footer">
    <span>Need an account?&nbsp;</span>
    <a [href]="signUpUrl" class="form-change-button">Create an account</a>
  </div>
</div>
