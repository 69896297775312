import { Injectable } from '@angular/core';
import { HTTP_CP_Property, PropertyApiService } from '@api-new/propertyservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { addPropertySuccess, getProperties } from '@shared/store/properties/properties.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ErrorModel } from '../../models/app-state.model';
import { ErrorService } from '../../services/error.service';
import { ToastService } from '../../services/toast.service';
import * as propertyActions from './property.action';

@Injectable()
export class PropertyEffect {
  getProperties$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.GetPropertiesAction.type),
      switchMap(() =>
        this.propertyApiService.HTTP_CP_ListProperties().pipe(
          map(({ properties }) => new propertyActions.GetPropertiesSuccessAction(properties != null ? properties : [])),
          catchError((error) => {
            this.errorService.pushError(error);
            return of(new propertyActions.GetPropertiesFailureAction(error));
          }),
        ),
      ),
    ),
  );

  getSingleProperty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.GetSinglePropertyAction.type),
      map((action: propertyActions.GetSinglePropertyAction) => action.payload),
      switchMap((id) =>
        this.propertyApiService.HTTP_CP_GetProperty({ id }).pipe(
          mergeMap((property: HTTP_CP_Property) => [new propertyActions.GetSinglePropertySuccessAction(property)]),
          catchError((error) => {
            this.errorService.pushError(error);
            return of(new propertyActions.GetSinglePropertyFailureAction(error));
          }),
        ),
      ),
    ),
  );

  createProperty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.CreatePropertyAction.type),
      map((action: propertyActions.CreatePropertyAction) => action.payload),
      switchMap((property) =>
        this.propertyApiService
          .HTTP_CP_CreateProperty({
            ...property,
          })
          .pipe(
            map((newProperty) => {
              this.toastService.showSuccess({ headLine: 'Create', text: 'Property was successfully created' });
              return new propertyActions.CreatePropertySuccessAction(newProperty);
            }),
            catchError(({ error }: { error: ErrorModel }) => {
              this.toastService.showError({ headLine: 'Property error', text: error.message });
              return of(new propertyActions.CreatePropertyFailureAction(error));
            }),
          ),
      ),
    ),
  );

  handleCreatePropertySuccessAction = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.CreatePropertySuccessAction.type),
      map(() => getProperties()),
    ),
  );

  handleNewCreatePropertySuccessAction = createEffect(() =>
    this.actions$.pipe(
      ofType(addPropertySuccess.type),
      map(() => new propertyActions.GetPropertiesAction()),
    ),
  );

  updateProperty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.UpdatePropertyAction.type),
      map((action: propertyActions.UpdatePropertyAction) => action.payload),
      switchMap((property) => {
        // TODO: michaela.kaftanova 'delete property.fkUser' and 'delete property.clientUserId' needs to be here until we split the requests models. The EP will fail if fkUser or clientUserId is sent
        delete property['fkUser'];
        // delete property.clientUserId;
        return this.propertyApiService.HTTP_CP_UpdateProperty(property).pipe(
          map((updateProperty) => {
            this.toastService.showSuccess({ headLine: 'Update', text: 'Property was successfully updated' });
            return new propertyActions.UpdatePropertySuccessAction(updateProperty);
          }),
          catchError((error) => {
            this.toastService.showError({ headLine: 'Update', text: 'Property failed to updated' });
            return of(new propertyActions.UpdatePropertyFailureAction(error));
          }),
        );
      }),
    ),
  );

  closeProperty$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.ClosePropertyAction.type),
      map((action: propertyActions.ClosePropertyAction) => action.payload),
      switchMap((closingModel) =>
        this.propertyApiService
          .HTTP_CP_CloseProperty({ reason: closingModel.reason, when: closingModel.when.toString(), propertyId: closingModel.id })
          .pipe(
            map(() => {
              this.toastService.showSuccess({
                headLine: 'Close',
                text: 'Property was successfully closed',
              });
              return new propertyActions.ClosePropertySuccessAction(closingModel);
            }),
            catchError((error) => {
              this.toastService.showError({ headLine: 'Close', text: 'Property close failed' });
              return of(new propertyActions.ClosePropertyFailureAction(error));
            }),
          ),
      ),
    ),
  );

  handleClosePropertySuccessAction = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.ClosePropertySuccessAction.type),
      map(() => getProperties()),
    ),
  );

  propertyValuationDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.DeletePropertyValuationAction.type),
      map((action: propertyActions.DeletePropertyValuationAction) => action.payload),
      switchMap((valuation) =>
        this.propertyApiService.HTTP_CP_UnsetPropertyValue(valuation).pipe(
          map(
            (valuations) =>
              new propertyActions.DeletePropertyValuationSuccessAction({
                propertyId: valuation.propertyId,
                response: valuations,
              }),
          ),
          catchError((error) => {
            this.toastService.showError({
              headLine: 'Valuation delete',
              text: 'Unexpected error occurred while deleting property valuation.',
            });
            return of(new propertyActions.DeletePropertyValuationFailureAction(error));
          }),
        ),
      ),
    ),
  );

  propertyValuationCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertyActions.CreatePropertyValuationAction.type),
      map((action: propertyActions.CreatePropertyValuationAction) => action.payload),
      switchMap((valuation) =>
        this.propertyApiService.HTTP_CP_SetPropertyValue(valuation).pipe(
          map((response) => new propertyActions.CreatePropertyValuationSuccessAction(response)),
          catchError((error) => {
            this.toastService.showError({
              headLine: 'Valuation create',
              text: 'Unexpected error occurred while creating property valuation.',
            });
            return of(new propertyActions.CreatePropertyValuationFailureAction(error));
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly propertyApiService: PropertyApiService,
    private readonly errorService: ErrorService,
    private readonly toastService: ToastService,
  ) {}
}
