import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserActionsRequestModel } from '@app/OLD_shared/models/UserActionsRequestModel';
import { AppState } from '@app/OLD_shared/models/app-state.model';
import { ActionsSubject, Store, select } from '@ngrx/store';
import { logoutAndReloadAction } from '@shared/store/auth/auth.action';
import { deleteClientUser, deleteClientUserFailure, deleteClientUserSuccess } from '@shared/store/client-user/client-user.actions';
import { selectClientUserIsLoading, selectClientUserLandingScreenLogo } from '@shared/store/client-user/client-user.selectors';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

enum DeletionState {
  DELETED,
  FAILED,
}

@Component({
  selector: 'cp-deleted',
  standalone: true,
  imports: [AsyncPipe, NgClass],
  templateUrl: './deleted.component.html',
  styleUrls: ['../auth-theme.scss'],
})
export class DeletedComponent {
  readonly relevantLandingScreenLogo$: Observable<string> = this.store.pipe(select(selectClientUserLandingScreenLogo));
  readonly deleteLoading$: Observable<boolean> = this.store.pipe(select(selectClientUserIsLoading));
  readonly DeletionState = DeletionState;

  deletionState: DeletionState;
  queryParams: UserActionsRequestModel;
  logoLoading = true;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dispatcher: ActionsSubject,
    private readonly route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((queryParams: UserActionsRequestModel) => {
      this.queryParams = queryParams;
      this.store.dispatch(deleteClientUser({ code: this.queryParams.code }));
      this.dispatcher
        .pipe(
          filter((action) => action.type === deleteClientUserSuccess.type || action.type === deleteClientUserFailure.type),
          take(1),
        )
        .subscribe((action) => {
          if (action.type === deleteClientUserSuccess.type) {
            this.deletionState = DeletionState.DELETED;
          } else {
            this.deletionState = DeletionState.FAILED;
          }
        });
    });
  }

  logout(): void {
    this.store.dispatch(logoutAndReloadAction());
  }

  setLogoLoading(isLoading: boolean): void {
    this.logoLoading = isLoading;
  }
}
