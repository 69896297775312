// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_PropertyTenure.proto

export enum ENUM_PropertyTenure {
  PROPERTY_TENURE_UNSPECIFIED = 'PROPERTY_TENURE_UNSPECIFIED',
  PROPERTY_TENURE_FREEHOLD = 'PROPERTY_TENURE_FREEHOLD',
  PROPERTY_TENURE_LEASEHOLD = 'PROPERTY_TENURE_LEASEHOLD',
  PROPERTY_TENURE_SHARE_OF_FREEHOLD = 'PROPERTY_TENURE_SHARE_OF_FREEHOLD',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
