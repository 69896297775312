import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { AppState } from '@app/OLD_shared/models/app-state.model';
import { GlobalColorService } from '@app/OLD_shared/services/global-color.service';
import { $remoteConfigLoading } from '@app/OLD_shared/store/selectors/remote-config.selectors';
import { environment } from '@environments/environment';
import { init as initFullStory } from '@fullstory/browser';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'cp-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  readonly appLoading$ = combineLatest([
    this.router.events.pipe(
      filter((event) => event instanceof ChildActivationEnd),
      take(1),
      map(() => false),
      startWith(true),
    ),
    this.store.pipe(select($remoteConfigLoading)),
  ]).pipe(map(([appLoading, configLoading]) => appLoading || configLoading));

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly globalColorService: GlobalColorService,
  ) {
    this.initFullStory();
    this.globalColorService.initCompanyColorListener();

    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((data: { title: string }) => {
        this.titleService.setTitle(`${data.title ? `${data.title} | ` : ''}Mortgage Monitor`);
      });

    // TODO: Implement new maintenance mechanism
    // Firebase remote config
    // remoteConfig.parameters
    //   .pipe(
    //     map((currentRemoteConfig: Parameter[]) => {
    //       const remappedParameter: RemoteConfigModel = {
    //         maintenanceMode: {
    //           enabled: false,
    //           date: null,
    //         },
    //       };
    //       currentRemoteConfig.forEach((parameter) => {
    //         try {
    //           // @ts-ignore
    //           (remappedParameter[parameter.key] as any) = JSON.parse(parameter._value);
    //         } catch (error) {
    //           // @ts-ignore
    //           (remappedParameter[parameter.key] as any) = parameter._value;
    //         }
    //       });
    //       return remappedParameter;
    //     }),
    //   )
    //   .subscribe((currentRemoteConfig: RemoteConfigModel) => {
    //     this.store.dispatch(new SetRemoteConfigAction(currentRemoteConfig));
    //   });
  }

  private initFullStory(): void {
    initFullStory({
      orgId: '9ZEQS',
      devMode: !environment.production,
    });
  }
}
