import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { HTTP_CP_MortgageCase } from '@api-new/caseservice';
import {
  ENUM_ClientUserEmploymentFullTimeType,
  ENUM_ClientUserEmploymentOtherIncomeType,
  ENUM_ClientUserEmploymentPartTimeType,
  ENUM_ClientUserEmploymentSelfEmployedType,
  ENUM_ClientUserEmploymentType,
  ENUM_ManualPropertyValueType,
  ENUM_MortgagePartOverpaymentType,
  ENUM_MortgagePartStepType,
  ENUM_MortgagePartUnderpaymentType,
  ENUM_MortgageProductDistributionType,
  ENUM_MortgageRepaymentType,
  ENUM_MortgageType,
  ENUM_PropertyInvestmentType,
  ENUM_PropertyTenure,
  ENUM_PropertyType,
} from '@api-new/common';
import { HTTP_X_ListLenders_Response_Lender } from '@api-new/mortgageservice';
import { HTTP_CP_Property } from '@api-new/propertyservice';
import { DateTimeFormats } from '@app/OLD_shared/enums/date-time-formates.enum';
import { PeriodUnits } from '@app/OLD_shared/enums/period-units.enum';
import { MAX_MORTGAGE_DURATION_IN_MONTHS } from '@shared-lib/constants';
import moment, { Moment } from 'moment';
import { PaymentStatus } from './enums/accounts.enum';
import { MimeTypeEnum } from './enums/document.enum';
import { PasswordConditionsEnum } from './enums/password-conditions.enum';
import { SelectInterface } from './form-components/select/select.component';
import { CompanyIddType } from './models/broker-details.model';
import { MortgageEditMenuEnum, MortgageEditTabModel, MortgageModel } from './models/mortgage.model';
import { PropertyValuationChangeModel } from './models/property-valuation.model';

/**
 * ----------------------- CONSTANTS -----------------------
 */

export const UK_PHONE_NUMBER_REGEX =
  '^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$'; // tslint:disable:max-line-length

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 128;

export const LENDER_LOGO_PLACEHOLDER = 'assets/OLD_images/lender_logo_placeholder.svg';
export const PROPERTY_IMAGE_PLACEHOLDER = 'assets/images/house-residential.png';
export const PROFILE_IMAGE_PLACEHOLDER = 'assets/OLD_images/profile-placeholder-image.png';
export const DASHLY_COMPANY_ID = '1';
export const PROFILE_NAME_PLACEHOLDER = ` `;

export const MORTGAGE_GRAPHS_MAX_PERIOD = 12; // months
export const ANGULAR_CDK_DESTROY_DELAY = 150;
export const GRAPH_LABEL_DEFAULTS = Array(MORTGAGE_GRAPHS_MAX_PERIOD)
  .fill(0)
  .map((x, i) =>
    moment()
      .local()
      .subtract(MORTGAGE_GRAPHS_MAX_PERIOD - i - 1, PeriodUnits.MONTH)
      .format(DateTimeFormats.MMMMYYYY_COMMA),
  );
export const MIN_YEAR = moment().year() - MAX_MORTGAGE_DURATION_IN_MONTHS / 12;
export const MIN_DATE: moment.Moment = moment().startOf(PeriodUnits.YEAR).year(1970);
export const MIN_BIRTH_DATE: moment.Moment = moment().startOf(PeriodUnits.YEAR).year(1900);
export const MAX_BIRTH_DATE: moment.Moment = moment().subtract(18, PeriodUnits.YEAR);
export const DEFAULT_COMPARISON_PERIOD = 24; // months
export const MONTHS_IN_YEAR = 12;
export const COMPARISON_PERIODS = Array(5)
  .fill(0)
  .map((_, index) => (index + 1) * 12);
export const VALUATION_SOURCES: SelectInterface<ENUM_ManualPropertyValueType>[] = [
  { value: ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LAND_REGISTRY, label: 'Land registry' },
  { value: ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_MORTGAGE_OFFER, label: 'Mortgage offer' },
  // { value: ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_INSPECTION, label: 'Inspection' },
  { value: ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_WEBSITE, label: 'Website' },
  { value: ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_CLIENT_ESTIMATION, label: 'My estimate' },
];
export const SHARED_OWNERSHIP_OPTIONS: SelectInterface<number>[] = Array(19)
  .fill(0)
  .map((item, index) => ({ value: ((index + 1) * 5) / 100, label: `${(index + 1) * 5}%` }));
export const TITLE_OPTIONS: SelectInterface<string>[] = ['Mr', 'Mrs', 'Ms', 'Miss', 'Mx', 'Dr'].map((item) => ({
  value: item,
}));
export const EMPLOYMENT_STATUS_OPTIONS: SelectInterface<ENUM_ClientUserEmploymentType>[] = [
  { value: ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_FULL_TIME, label: 'Full-time' },
  { value: ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_PART_TIME, label: 'Part-time' },
  { value: ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_SELF_EMPLOYED, label: 'Self-employed' },
  { value: ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_RETIRED, label: 'Retired' },
  { value: ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_UNEMPLOYED_HOME, label: 'Unemployed / home' },
];

export const DISTRIBUTION_TYPE_OPTIONS: SelectInterface<ENUM_MortgageProductDistributionType>[] = [
  {
    label: 'Any',
    value: ENUM_MortgageProductDistributionType.MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_ANY_DISTRIBUTION,
  },
  {
    label: 'Only intermediary',
    value: ENUM_MortgageProductDistributionType.MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_INTERMEDIARY,
  },
  {
    label: 'Only direct',
    value: ENUM_MortgageProductDistributionType.MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_DIRECT,
  },
];

export const SELF_EMPLOYMENT_OPTIONS: SelectInterface<ENUM_ClientUserEmploymentSelfEmployedType>[] = [
  { value: null, label: 'Select Type' },
  {
    value: ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_SOLE_TRADER,
    label: 'Sole trader',
  },
  {
    value: ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_PARTNERSHIP,
    label: 'Partnership',
  },
  {
    value: ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_LIMITED_COMPANY,
    label: 'Limited Company',
  },
  { value: ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_LLP, label: 'LLP' },
];

export const OTHER_INCOME_SOURCE_OPTIONS: SelectInterface<ENUM_ClientUserEmploymentOtherIncomeType>[] = [
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_BONUS_FROM_ANOTHER_JOB,
    label: 'Bonus from another job',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_PENSION,
    label: 'Pension',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_MAINTENANCE_PAYMENTS,
    label: 'Maintenance payments',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_CAR_ALLOWANCE__FROM_ANOTHER_JOB,
    label: 'Car allowance from another job',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_COMMISSIONS_FROM_ANOTHER_JOB,
    label: 'Commissions from another job',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_RENTAL_INCOME,
    label: 'Rental income',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_NET_PROFIT_FROM_ANOTHER_JOB,
    label: 'Net profit from another job',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_OVERTIME__FROM_ANOTHER_JOB,
    label: 'Overtime from another job',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_SALARY_FROM_ANOTHER_JOB,
    label: 'Salary from another job',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_PART_TIME,
    label: 'Part time',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_HOUSE_ALLOWANCE_FROM_ANOTHER_JOB,
    label: 'House allowance from another job',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_STATE_BENEFIT,
    label: 'State benefit',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_DIVIDENDS_FROM_ANOTHER_JOB,
    label: 'Dividends from another job',
  },
  {
    value: ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_INVESTMENT_INCOME,
    label: 'Investment income',
  },
];

export const EMPLOYMENT_FULL_TIME_TYPE_OPTIONS: SelectInterface<ENUM_ClientUserEmploymentFullTimeType>[] = [
  { value: null, label: 'Select Type' },
  { value: ENUM_ClientUserEmploymentFullTimeType.CLIENT_USER_EMPLOYMENT_FULL_TIME_TYPE_PERMANENT, label: 'Permanent' },
  { value: ENUM_ClientUserEmploymentFullTimeType.CLIENT_USER_EMPLOYMENT_FULL_TIME_TYPE_TEMPORARY, label: 'Temporary' },
];

export const EMPLOYMENT_PART_TIME_TYPE_OPTIONS: SelectInterface<ENUM_ClientUserEmploymentPartTimeType>[] = [
  { value: null, label: 'Select Type' },
  { value: ENUM_ClientUserEmploymentPartTimeType.CLIENT_USER_EMPLOYMENT_PART_TIME_TYPE_PERMANENT, label: 'Permanent' },
  { value: ENUM_ClientUserEmploymentPartTimeType.CLIENT_USER_EMPLOYMENT_PART_TIME_TYPE_TEMPORARY, label: 'Temporary' },
];

export const BANK_OF_ENGLAND_NAME = 'Bank of England';
export const PROPERTY_BEDROOMS_OPTIONS: SelectInterface<number>[] = Array(6)
  .fill(0)
  .map((_, index) => ({ label: `${index + 1}`, value: index + 1 }));

export const INVESTMENT_TYPES: SelectInterface<ENUM_PropertyInvestmentType>[] = [
  { value: ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL, label: 'Residential' },
  { value: ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_BUY_TO_LET, label: 'Buy to let' },
  { value: ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_UNSPECIFIED, label: 'Unspecified' },
];

export const TENURE_TYPES: SelectInterface<ENUM_PropertyTenure>[] = [
  { value: ENUM_PropertyTenure.PROPERTY_TENURE_LEASEHOLD, label: 'Leasehold' },
  { value: ENUM_PropertyTenure.PROPERTY_TENURE_FREEHOLD, label: 'Freehold' },
  { value: ENUM_PropertyTenure.PROPERTY_TENURE_SHARE_OF_FREEHOLD, label: 'Share of Freehold' },
  { value: ENUM_PropertyTenure.PROPERTY_TENURE_UNSPECIFIED, label: 'Not known' },
];

export const PROPERTY_TYPES: SelectInterface<ENUM_PropertyType>[] = [
  { value: ENUM_PropertyType.PROPERTY_TYPE_DETACHED, label: 'Detached house' },
  { value: ENUM_PropertyType.PROPERTY_TYPE_SEMI_DETACHED, label: 'Semi-detached house' },
  { value: ENUM_PropertyType.PROPERTY_TYPE_FLAT, label: 'Flat' },
  { value: ENUM_PropertyType.PROPERTY_TYPE_MAISONETTE, label: 'Maisonette' },
  { value: ENUM_PropertyType.PROPERTY_TYPE_TERRACED, label: 'Terraced house' },
  { value: ENUM_PropertyType.PROPERTY_TYPE_HALL, label: 'Hall House' },
  { value: ENUM_PropertyType.PROPERTY_TYPE_UNSPECIFIED, label: 'Not known' },
];

export const OVERPAYMENT_INTERVAL_TYPES: SelectInterface<ENUM_MortgagePartOverpaymentType>[] = [
  { label: 'One time', value: ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_ONE_TIME },
  { label: 'Recurring', value: ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_RECURRING },
];

export const UNDERPAYMENT_INTERVAL_TYPES: SelectInterface<ENUM_MortgagePartUnderpaymentType>[] = [
  { label: 'One time', value: ENUM_MortgagePartUnderpaymentType.MORTGAGE_PART_UNDERPAYMENT_TYPE_ONE_TIME },
  { label: 'Recurring', value: ENUM_MortgagePartUnderpaymentType.MORTGAGE_PART_UNDERPAYMENT_TYPE_RECURRING },
];

export const mortgageRateTypeSelectItems: SelectInterface<ENUM_MortgagePartStepType>[] = [
  { label: 'Fixed', value: ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_FIXED },
  { label: 'Variable', value: ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_VARIABLE },
];

export const mortgageInitialPeriodSelectItems: SelectInterface<number>[] = [
  { label: '1 year', value: 12 },
  { label: '2 years', value: 24 },
  { label: '3 years', value: 36 },
  { label: '4 years', value: 48 },
  { label: '5 years', value: 60 },
  { label: '6 years', value: 72 },
  { label: '7 years', value: 84 },
  { label: '8 years', value: 96 },
  { label: '9 years', value: 108 },
  { label: '10 years', value: 120 },
  { label: '15 years', value: 180 },
  { label: '20 years', value: 240 },
];
export const DEFAULT_INITIAL_PERIOD = 24;

export const SELECT_INITIAL_PERIODS_FULL: SelectInterface<number>[] = [
  { label: 'No initial period', value: 0 },
  ...mortgageInitialPeriodSelectItems,
];

export const repaymentTypeSelectItems: SelectInterface<ENUM_MortgageRepaymentType>[] = [
  { label: 'Full repayment', value: ENUM_MortgageRepaymentType.MORTGAGE_REPAYMENT_TYPE_FULL_REPAYMENT },
  { label: 'Interest only', value: ENUM_MortgageRepaymentType.MORTGAGE_REPAYMENT_TYPE_INTEREST_ONLY },
];

export type InterestRateTooltipState = 'warningHigh' | 'warningLow' | 'errorHigh' | 'errorLow' | null;
export const INTEREST_RATE_THRESHOLDS = {
  absoluteMin: 0.001,
  warningMin: 0.004,
  warningMax: 0.07,
  absoluteMax: 0.15,
};
export const LOAN_AMOUNT_THRESHOLD_MIN = 25000;
export const LOAN_AMOUNT_THRESHOLD_MAX = 10000000;

export const mimeTypeStringToEnumMap: { [key: string]: MimeTypeEnum } = {
  'application/msword': MimeTypeEnum.MIME_TYPE_MSWORD,
  'application/pdf': MimeTypeEnum.MIME_TYPE_PDF,
  'application/excel': MimeTypeEnum.MIME_TYPE_EXCEL,
  'application/x-excel': MimeTypeEnum.MIME_TYPE_XEXCEL,
  'image/jpeg': MimeTypeEnum.MIME_TYPE_JPEG,
  'image/pjpeg': MimeTypeEnum.MIME_TYPE_PJPEG,
  'image/png': MimeTypeEnum.MIME_TYPE_PNG,
  'image/gif': MimeTypeEnum.MIME_TYPE_GIF,
};

export const MORTGAGE_EDIT_SUMMARY_TAB: MortgageEditTabModel = {
  index: 0,
  type: MortgageEditMenuEnum.MORTGAGE_SUMMARY,
  label: 'summary',
};

export const MORTGAGE_EDIT_OVERPAYMENTS_TAB: MortgageEditTabModel = {
  index: 1,
  type: MortgageEditMenuEnum.OVERPAYMENTS,
  label: 'overpayments',
  partIndex: 0,
};

export const MORTGAGE_EDIT_UNDERPAYMENTS_TAB: MortgageEditTabModel = {
  index: 2,
  type: MortgageEditMenuEnum.UNDERPAYMENTS,
  label: 'underpayments',
  partIndex: 0,
};

export const DEFAULT_LOGOUT_TIMEOUT_MINUTES = 60;

export const HELP_LINK = 'https://help.dashly.com';
export const DASHLY_LINKS = {
  facebook: 'https://www.facebook.com/DashlyHQ',
  twitter: 'https://www.twitter.com/DashlyHQ',
  termsOfUse: 'https://www.dashly.com/terms',
  privacyPolicy: 'https://www.dashly.com/terms/privacy',
  cookiePolicy: 'https://www.dashly.com/terms/cookie',
  about: 'https://www.dashly.com/about',
  help: HELP_LINK,
  siteMap: 'https://dashly.com/sitemap.xml',
};

/**
 * ----------------------- FUNCTIONS -----------------------
 */

export const sortNumberArray = (array: number[]): number[] => array.sort((a, b) => a - b);

/**
 * Useful to safely convert string | Moment into Moment
 * @returns new Moment, or null if date parameter can't be converted to valid Moment object
 * @param date
 * @param format
 */
export function momentify(date: string | moment.Moment, format?: moment.MomentFormatSpecification): moment.Moment {
  if (date == null) {
    return null;
  }
  const newDate = format ? moment(date) : moment(date, format);
  return newDate.isValid() ? newDate : null;
}

export function getLenderLogoUrl(lender: HTTP_X_ListLenders_Response_Lender): string {
  return lender == null || lender.logo == null ? LENDER_LOGO_PLACEHOLDER : lender.logo;
}

export function divideLenders(lenders: HTTP_X_ListLenders_Response_Lender[]): {
  mainLenders: HTTP_X_ListLenders_Response_Lender[];
  allLenders: HTTP_X_ListLenders_Response_Lender[];
} {
  let mainLenders: HTTP_X_ListLenders_Response_Lender[] = [];
  let allLenders: HTTP_X_ListLenders_Response_Lender[] = [];
  if (lenders != null) {
    mainLenders = lenders
      .filter((lender: HTTP_X_ListLenders_Response_Lender) => +lender.rank > 0 && +lender.rank <= 5)
      .sort((a, b) => +a.rank - +b.rank);
    allLenders = lenders;
  }
  return { mainLenders, allLenders };
}

export function capitalize(str: string): string {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function average(input: number[]): number {
  if (input == null || input.length < 1) {
    console.error('Could not find average of: ', input);
    return 0;
  }
  const sum = input.reduce((a, b) => a + b);
  return sum / input.length;
}

export function numberToOrderString(num: number): string {
  if (num === null || num < 0) {
    return `Invalid number "${num}"`;
  }
  const i = num % 10;
  const j = num % 100;

  if (i === 1 && j !== 11) {
    return `${num}st`;
  } else if (i === 2 && j !== 12) {
    return `${num}nd`;
  } else if (i === 3 && j !== 13) {
    return `${num}rd`;
  } else {
    return `${num}th`;
  }
}

export function getEndOfInitialPeriod(mortgage: MortgageModel): moment.Moment {
  if (mortgage == null) {
    return null;
  }
  return moment(mortgage.mortgageParts[0].startDate).add(mortgage.mortgageParts[0].initialPeriod, PeriodUnits.MONTH);
}

export function getComparisonPeriods(mortgage: MortgageModel): SelectInterface<number>[] {
  let comparisonPeriods: SelectInterface<number>[] = COMPARISON_PERIODS.map((item) => ({
    value: item,
    label: `${item} months`,
  }));
  if (mortgage != null) {
    const endOfInitialPeriod = getEndOfInitialPeriod(mortgage);
    if (endOfInitialPeriod == null) {
      return comparisonPeriods;
    }
    if (endOfInitialPeriod.startOf(PeriodUnits.MONTH).isAfter(moment().startOf(PeriodUnits.MONTH), PeriodUnits.DAY)) {
      const remainingTime = endOfInitialPeriod.startOf(PeriodUnits.MONTH).diff(moment().startOf(PeriodUnits.MONTH), PeriodUnits.MONTH);
      comparisonPeriods = [{ value: remainingTime, label: `${remainingTime} months` }, ...comparisonPeriods];
    }
  }
  return comparisonPeriods;
}

export function getPaymentChange(mortgage: MortgageModel): PaymentStatus {
  if (mortgage == null || mortgage.currentMonthlyPayment == null || mortgage.steps == null || mortgage.steps.length < 1) {
    return PaymentStatus.Normal;
  }
  const monthlyPayment = mortgage.currentMonthlyPayment;
  const lastPayment = mortgage.steps[mortgage.steps.length - 1];
  return monthlyPayment > lastPayment
    ? PaymentStatus.Increment
    : monthlyPayment < lastPayment
      ? PaymentStatus.Decrement
      : PaymentStatus.Normal;
}

export function svrTypeToReadable(type: string): string {
  return type == null ? 'Unknown type' : capitalize(type.split('_').join(' '));
}

export function getInterestRateTooltipState(rate: number): InterestRateTooltipState {
  if (rate == null) {
    return null;
  }
  switch (true) {
    case rate > INTEREST_RATE_THRESHOLDS.absoluteMax:
      return 'errorHigh';

    case rate < INTEREST_RATE_THRESHOLDS.absoluteMin:
      return 'errorLow';

    case rate > INTEREST_RATE_THRESHOLDS.warningMax:
      return 'warningHigh';

    case rate < INTEREST_RATE_THRESHOLDS.warningMin:
      return 'warningLow';

    default:
      return null;
  }
}

/**
 * @param duration
 * @returns duration in "XX year[-s] and YY month[-s]" format
 * If either years or months value is 0, it completely trims it from format
 * e.g. only "10 years" or "2 months".
 */
export function getFormattedDuration(duration: moment.Duration): string {
  if (duration == null) {
    return 'unknown';
  }
  const momentDuration: any = moment.duration(duration);
  if (momentDuration.asMonths() < 1) {
    return 'less than month';
  }
  // regex => if string ends with " and", removes it.
  // This is used because moment `{trim: 'both'}` removes months value if months === 0, but leave the " and"
  return momentDuration.format('Y [year] [and] M [month]', { trim: 'both' }).match(/.*?(?=(?: and)?$)/)![0];
}

export function getFormattedDurationMonths(durationMonths: number): string {
  if (durationMonths == null || typeof durationMonths !== 'number' || durationMonths < 1) {
    return null;
  }
  return getFormattedDuration(moment.duration(durationMonths, PeriodUnits.MONTH));
}

export function getDurationInMonths(startDate: string | moment.Moment, endDate: string | moment.Moment): number {
  const momentStartDate = momentify(startDate);
  const momentEndDate = momentify(endDate);
  if (momentStartDate == null || momentEndDate == null) {
    return null;
  }
  return Math.round(moment.duration(momentEndDate.startOf(PeriodUnits.MONTH).diff(momentStartDate.startOf(PeriodUnits.MONTH))).asMonths());
}

export const getUpdatedRateDate = (date: Moment | string, monthsDiff: number, dayOfMonth: number, isLastDayOfMonth?: boolean): Moment => {
  if (isLastDayOfMonth) {
    return moment(date).add(monthsDiff, PeriodUnits.MONTH).endOf(PeriodUnits.MONTH);
  }
  if (moment(date).daysInMonth() < dayOfMonth) {
    return moment(date).add(monthsDiff, PeriodUnits.MONTH).date(moment(date).daysInMonth());
  }
  return moment(date).add(monthsDiff, PeriodUnits.MONTH).date(dayOfMonth);
};

export function passwordMatchValidator(
  passwordKey1: string,
  passwordKey2: string,
): (group: UntypedFormGroup) => { [err: string]: boolean } {
  return (group: UntypedFormGroup): { [err: string]: boolean } =>
    group.get(passwordKey1).value !== group.get(passwordKey2).value ? { mismatch: true } : null;
}

export function hasPasswordError(control: AbstractControl, condition: PasswordConditionsEnum): boolean {
  return control.errors && control.errors.password && control.errors.password[condition];
}

export function touchAllChildren(formGroup: UntypedFormGroup): void {
  formGroup.markAllAsTouched();
}

export function showWarnState(field: string, formGroup: UntypedFormGroup): boolean {
  const fieldControl = formGroup.controls[field];
  if (fieldControl == null) {
    return false;
  }
  return fieldControl.touched && fieldControl.invalid;
}

export function joinPath(...args: string[]): string {
  return args.join('/');
}

export function createPropertyValuationData(
  property: HTTP_CP_Property,
  valuationHistory: PropertyValuationChangeModel[],
): { label: string; valuation: number }[] {
  return (valuationHistory && valuationHistory.length > 1 ? valuationHistory : Array(MORTGAGE_GRAPHS_MAX_PERIOD).fill({}))
    .slice(-MORTGAGE_GRAPHS_MAX_PERIOD)
    .map((item: PropertyValuationChangeModel, index: number) => ({
      valuation: item.valuation || property?.roundedCurrentValue || 0,
      label: momentify(item.date) ? momentify(item.date).format(DateTimeFormats.MMMMYYYY_COMMA) : GRAPH_LABEL_DEFAULTS[index],
    }))
    .sort((a, b) => moment(a.label, DateTimeFormats.MMMMYYYY_COMMA).unix() - moment(b.label, DateTimeFormats.MMMMYYYY_COMMA).unix());
}

export function getMortgageCaseTitle(mortgageCase: HTTP_CP_MortgageCase, property: HTTP_CP_Property): string {
  if (mortgageCase == null) {
    return 'Remortgage';
  }
  return property != null ? `Remortgage of ${property.addressLines.lines[0]}` : `Remortgage ${mortgageCase.id}`;
}

/**
 * Creates company identifier string, used in Fullstory and Google analytics
 * @param companyId
 * @param isAdmin
 */
export const companyIdentifier = (companyId: string, isAdmin: boolean): string => {
  if (isAdmin) {
    return '[sys-admin]';
  }
  if (companyId == null) {
    return 'N/A';
  }
  return `[${companyId}]`;
};

export const mortgageTypeToCompanyIdd = (mortgageType: ENUM_MortgageType): CompanyIddType => {
  switch (mortgageType) {
    case ENUM_MortgageType.MORTGAGE_TYPE_BUY_TO_LET:
      return CompanyIddType.COMPANY_IDD_TYPE_BUY_TO_LET;
    case ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL:
      return CompanyIddType.COMPANY_IDD_TYPE_RESIDENTIAL;
    default:
      return null;
  }
};
