export const paths = {
  PLATFORM: 'platform',
  MAINTENANCE: 'maintenance',
  UNSUBSCRIBE: 'unsubscribe',
  AUTH: 'auth',
  AGGREGATOR_LANDING_PAGE: 'a',
  auth: {
    REGISTER: 'register',
    LOGIN: 'login',
    LOGIN_VERIFICATION: 'login-verification',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
  },
  aggregatorLandingPage: {
    CLEARSCORE: 'clearscore',
    clearscore: {
      BOOK_A_CALLBACK: 'book-a-callback',
      CALLBACK_REQUESTED: 'callback-requested',
      UNSUBSCRIBE: 'unsubscribe',
    },
  },
  platform: {
    DASHBOARD: 'dashboard',
    PROPERTIES: 'properties',
    SMART_SEARCH: 'smart-search',
    FIRST_TIME: 'first-time',
    MORTGAGES: 'mortgages',
    MORTGAGE_DETAIL: 'mortgage-detail',
    MORTGAGE_CASE_DETAIL: 'mortgage-case-detail',
    HELP_CASE: 'enquiries',
    INSURANCES: 'insurances',
    DOCUMENTS: 'documents',
    PROPERTY: 'property',
    PROPERTY_DETAIL: 'property-detail',
    BETTER_DEAL_OVERALL: 'better-deal-overall',
    PROPERTY_MORTGAGE_PREVIEW: 'property-mortgage-preview',
    PROPERTY_MORTGAGE_DETAIL: 'property-mortgage-detail',
    BETTER_DEAL_COMPARISON: 'better-deal-comparison',
    END_OF_FIX_STRATEGY: 'end-of-fix-strategy',
    OPPORTUNITY_BETTER_DEAL_DETAIL: 'opportunity-better-deal-detail',
    CALENDAR: 'calendar',
    USER_PROFILE: 'user-profile',
    PROFILE: 'profile',
    REPORT: 'credit-reports-wizard',
    CASE: 'case',
    ERROR: 'error',
    mortgages: {
      ADD: 'add',
      LIST: 'list',
      DETAIL: 'mortgage',
      DIALOG: 'dialog',
      NEW_SEARCH: 'new-search',
      SEARCH_RESULTS: 'search-results',
      APPLICATION: 'application',
      MORTGAGE_CASE_ENQUIRY: 'mortgage-case-enquiry',
      COMPARE: 'compare',
      COMPARE_DETAIL: 'compare-detail',
      SEARCH_PREFERENCES: 'search-preferences',
      list: {
        MORTGAGES_LIST: 'all',
        NEW_PRODUCT: 'new-product',
        MORTGAGE_CASE_HISTORY: 'mortgage-case-history',
      },
      dialog: {
        PROPERTY_SELECT: 'property-select',
        REPLACEMENT: 'replacement',
        ACQUISITION: 'acquisition',
        PROVIDER: 'provider',
        TYPE: 'type',
        PARTS: 'parts',
        AMOUNT: 'amount',
        DURATION: 'duration',
        MONTHLY_PAYMENT: 'monthly-payment',
        SUMMARY: 'summary',
        STEP_SAVING: 'step-saving',
        COMPARISON_PERIOD: 'comparison-period',
        FINAL: 'final',
      },
      new_search: {
        REASON: 'reason',
        DEPOSIT: 'deposit',
        PRICE: 'price',
        DURATION: 'duration',
        REPAYMENT_TYPE: 'repayment-type',
        NAME: 'name',
        PROPERTY: 'property',
        DIFFERENCE: 'difference',
      },
      mortgage_case_enquiry: {
        START: 'start',
        PERSONAL: 'personal',
        APPLICANTS: 'applicants',
        FINAL_INFO: 'final-info',
        TAC: 'terms-and-conditions',
        END: 'end',
      },
      searchPreferences: {
        MORTGAGE_SELECT: 'select-mortgage',
        SAVINGS_TYPE: 'choose-method',
        COMPARISON_PERIOD: 'choose-period',
        INITIAL_RATE: 'choose-initial-rate',
        INITIAL_PERIOD: 'choose-initial-period',
        FINAL: 'final',
      },
    },
    insurances: {
      LIST: 'list',
      DETAIL: 'detail',
    },
    documents: {
      ALL: 'all',
      MORTGAGE: 'mortgage',
      PROPERTY: 'property',
      PERSONAL: 'personal',
    },
    property: {
      LIST: 'list',
      DETAIL: 'detail',
      ADD: 'add',
      MAP: 'map',
      EDIT: 'property-edit',
      add: {
        ADDRESS: 'address',
        USER_VALUATION: 'user-valuation',
        TYPE: 'type',
        RESIDENTIAL: 'residential',
        RESIDENTIAL_MORTGAGE: 'residential-mortgage',
        INFO: 'info',
        PRODUCTS: 'products',
      },
    },
    profile: {
      MENU: 'menu',
      SETTINGS: 'settings',
      SEARCH_PREFERENCES: 'search-preferences',
      NOTIFICATIONS_SETTING: 'notification-settings',
      ADVISOR: 'advisor',
      CREDIT_REPORT: 'credit-and-expenditure',
      INCOME: 'income-and-affordability',
      settings: {
        PERSONAL: 'personal-info',
        CREDIT: 'credit',
        EMPLOYMENT: 'employment',
        LIABILITIES: 'liabilities',
        LOGIN_HISTORY: 'login-history',
        HELP: 'help',
        NOTIFICATIONS: 'notifications',
        ACTIONS: 'actions',
      },
      credit_report: {
        REPORT: 'report',
        BAD_CREDIT: 'bad-credit',
      },
      income: {
        EMPLOYMENT: 'employment',
        AFFORDABILITY: 'affordability',
      },
    },
    profileDialog: {
      PERSONAL_INFO: 'personal-information',
      EMPLOYMENT: 'employment',
      CREDIT_REPORT: 'credit-and-expenditure',
    },
    report: {
      BIRTHDATE: 'birth-date',
      ADDRESS: 'address-history',
      DONE: 'done',
    },
  },
};
