import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { paths } from '../../../platform/paths';
import { AppState } from '../../models/app-state.model';
import { SetRemoteConfigAction } from '../actions/remote-config.action';
import { $routeURL } from '../selectors/route.selectors';

@Injectable()
export class RemoteConfigEffect {
  redirectToMaintenance: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SetRemoteConfigAction.type),
        tap((action: SetRemoteConfigAction) => {
          const maintenanceEnabled = action.payload['maintenanceMode'].enabled || false;
          this.store
            .select($routeURL)
            .pipe(take(1))
            .subscribe((url: string) => {
              if (url == null) {
                return;
              }
              if (url.startsWith(`/${paths.MAINTENANCE}`)) {
                if (!maintenanceEnabled) {
                  this.router.navigate(['/']);
                }
              } else {
                if (maintenanceEnabled) {
                  this.router.navigate([paths.MAINTENANCE]);
                }
              }
            });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}
}
