import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

interface ToastrMessage {
  headLine?: string;
  text: string;
}

@Injectable()
export class ToastService {
  readonly defaultToastBehavior: Partial<IndividualConfig> = {
    closeButton: true,
    timeOut: 3000,
    positionClass: 'toast-bottom-right',
    tapToDismiss: true,
  };

  constructor(private readonly toastr: ToastrService) {}

  showSuccess(message: ToastrMessage, options?: Partial<IndividualConfig>): void {
    this.toastr.success(message.text, message.headLine, { ...this.defaultToastBehavior, ...options });
  }

  showInfo(message: ToastrMessage, options?: Partial<IndividualConfig>): void {
    this.toastr.info(message.text, message.headLine, { ...this.defaultToastBehavior, ...options });
  }

  showWarning(message: ToastrMessage, options?: Partial<IndividualConfig>): void {
    this.toastr.warning(message.text, message.headLine, { ...this.defaultToastBehavior, ...options });
  }

  showError(message: ToastrMessage, options?: Partial<IndividualConfig>): void {
    this.toastr.error(message.text, message.headLine, { ...this.defaultToastBehavior, ...options });
  }

  showNotImplemented(message?: ToastrMessage, options?: Partial<IndividualConfig>): void {
    const newMessage = message || {
      headLine: 'Not available',
      text:
        'This feature is coming soon. Just by clicking you have voted for us to prioritise this feature! ' +
        'Thanks for supporting the pilot.',
    };
    this.showInfo(newMessage, { timeOut: 5000, ...options });
  }

  showTempDisabled(message?: ToastrMessage, options?: Partial<IndividualConfig>): void {
    const newMessage = message || {
      headLine: 'Not available',
      text:
        'This feature is currently disabled. Just by clicking you have voted for us to prioritise this feature! ' +
        'Thanks for supporting the pilot.',
    };
    this.showInfo(newMessage, { timeOut: 5000, ...options });
  }

  getSpecificToastId(message: string): number {
    return ((this.toastr.toasts || []).find((toast) => toast.message === message) || { toastId: null }).toastId;
  }

  closeSpecificToast(id: number): void {
    this.toastr.clear(id);
  }
}
