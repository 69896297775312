import { HTTP_CP_MortgageCase } from '@api-new/caseservice';
import { HTTP_CP_MortgageDeal, HTTP_X_ListLenders_Response_Lender } from '@api-new/mortgageservice';
import { HTTP_CP_Property } from '@api-new/propertyservice';
import { Moment } from 'moment';
import { ApplicantModel } from './applicant.model';
import { BaseModel } from './base.model';
import { AccountsMetadata } from './mortgage.model';

export enum ApplicationTypeEnum {
  APP_TYPE_UNKNOWN = 'APP_TYPE_UNKNOWN',
  APP_TYPE_EXPERT_CALLBACK = 'APP_TYPE_EXPERT_CALLBACK',
  APP_TYPE_CLICK_THROUGH = 'APP_TYPE_CLICK_THROUGH',
}

export interface ApplicationStatusModel {
  fkApplication?: string;
  timestamp?: string | Moment;
}

export interface MortgageCaseDescriptionModel extends BaseModel {
  fkMortgage?: string;
  fkProperty?: string;
  fkSmartSearch?: string;
  fkUser?: string;
  fkAdvisor?: string;

  currentStatus?: ApplicationStatusModel;
  type?: ApplicationTypeEnum;
  deal?: HTTP_CP_MortgageDeal;
  applicants?: ApplicantModel[];

  id?: string;
  mortgage?: {
    id: string;
    currentMonthlyPayment: number;
    lenderId: string;
    lenderLogo: string;
    lenderName: string;
    loanAmount: number;
    term: number;
  };
  property?: HTTP_CP_Property;
}

export interface MortgageCaseModel extends MortgageCaseDescriptionModel {
  cancelDate?: string | Moment;
  cancelReason?: string;

  revenueShareDashly?: number;
  revenueShareIntroducer?: number;
  commission?: number;
  estimatedCompletionDate?: string | Moment;
  dismissed?: boolean;
}

export interface MortgageCaseTrackerModel {
  mortgageCase: HTTP_CP_MortgageCase;
  property: HTTP_CP_Property;
  lender: HTTP_X_ListLenders_Response_Lender;
  betterDeal: HTTP_CP_MortgageDeal;

  // aggregated attributes
  lenderName: string;
  applicants: string;
  mortgageCaseName: string;
}

export interface MortgageCaseMetadataModel {
  taskUpdateLoading: boolean;
  createMortgageCaseLoading: boolean;
  newMortgageCase: HTTP_CP_MortgageCase;
  newMortgageCaseURLMetadata: AccountsMetadata;
}
