import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { showWarnState } from '@app/OLD_shared/common';
import { FormComponentsModule } from '@app/OLD_shared/form-components/form-components.module';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AuthFacadeService } from '../auth-facade.service';

@Component({
  selector: 'cp-login-email-input',
  templateUrl: './login-email-input.component.html',
  styleUrls: ['../auth.component.scss'],
  standalone: true,
  imports: [RouterLink, FormComponentsModule, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginEmailInputComponent {
  isAdviserLogInError$: Observable<boolean> = this.facade.isAdviserLogInError$;
  loading$: Observable<boolean> = this.facade.loading$;
  emailForm: UntypedFormGroup;
  noEmailFoundError$: Observable<boolean> = this.facade.noEmailFoundError$;

  signUpUrl = environment.signUpUrl;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly facade: AuthFacadeService,
  ) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  login(): void {
    if (this.emailForm.invalid) {
      return;
    }
    this.facade.checkLoginMail(this.emailForm.value.email);
  }

  showWarnState(email: string): boolean {
    return showWarnState(email, this.emailForm);
  }
}
