import { ENUM_PropertyInvestmentType } from '@api-new/common';
import { HTTP_CP_Property } from '@api-new/propertyservice';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { ExpandableRowData } from '../../basic-components/expandable-row/expandable-row.model';
import { INVESTMENT_TYPES, PROPERTY_TYPES, TENURE_TYPES } from '../../common';
import { SelectInterface } from '../../form-components/select/select.component';
import { AppState, PropertyState } from '../../models/app-state.model';
import { MortgageModel } from '../../models/mortgage.model';
import { PropertyProductRow } from '../../models/product-row.model';
import { selectPreparedNewMortgageCase } from '../mortgage-case/mortgage-case.selectors';
import { $mortgageList } from '../selectors/mortgage.selectors';

export const $propertyState = (state: AppState): PropertyState => state.OLD_property;

export const $propertyList = createSelector($propertyState, (state: PropertyState) =>
  Object.keys(state.entities)
    .reduce((acc, item) => [...acc, state.entities[item]], [])
    .filter((property) => !property.isClosed),
);

export const $propertyOptions = createSelector($propertyList, (properties: HTTP_CP_Property[]): SelectInterface<string>[] =>
  (properties || []).map((item) => ({ value: item.id, label: item?.addressLines?.lines.join(', ') })),
);

export const $propertiesLoaded = createSelector($propertyState, (state: PropertyState) => state.loaded);

// tslint:disable-next-line:typedef
export const $selectPropertyById = (id: string): MemoizedSelector<AppState, HTTP_CP_Property, (s1: PropertyState) => HTTP_CP_Property> =>
  createSelector($propertyState, (state: PropertyState) => (state.entities != null ? state.entities[id] : null));

export const $selectedProperty = createSelector(
  $propertyState,
  (state: PropertyState): HTTP_CP_Property => (state.entities != null ? state.entities[state.metadata.selectedProperty] : null),
);

export const $selectedPropertyName = createSelector($selectedProperty, (property: HTTP_CP_Property): string => property?.name);

export const $selectedPropertyType = createSelector($selectedProperty, (property: HTTP_CP_Property): string => {
  const propertyType = PROPERTY_TYPES.find((type) => type.value === (property && property.propertyType));
  return propertyType ? propertyType.label : 'Not known';
});

export const $selectedPropertyTenureType = createSelector($selectedProperty, (property: HTTP_CP_Property): string => {
  const tenureType = TENURE_TYPES.find((type) => type.value === (property && property.tenure));
  return tenureType ? tenureType.label : 'Not known';
});

export const $selectedPropertyInvestmentType = createSelector($selectedProperty, (property: HTTP_CP_Property): string => {
  const investmentType = INVESTMENT_TYPES.find((type) => type.value === property?.investmentType);
  return investmentType ? investmentType.label : 'Not known';
});

export const $propertyExpandableRowStreetInfo = createSelector($selectedProperty, (property: HTTP_CP_Property): ExpandableRowData => {
  let finalText: string;
  if (property == null || property.surroundingAreaInfo == null) {
    finalText = 'No information available.';
  } else {
    const textToReplace1 = 'The most expensive recorded transaction';
    const textToReplace2 = 'Of the';
    finalText = property.surroundingAreaInfo
      .replace(textToReplace1, `<br /><br />${textToReplace1}`)
      .replace(textToReplace2, `<br /><br />${textToReplace2}`);
  }
  return {
    id: '2',
    title: 'About my street',
    iconUrl: 'assets/icons/street-icon.svg',
    blockOfText: true,
    content: [
      {
        label: null,
        value: finalText,
      },
    ],
  };
});

export const $residentialProperty = createSelector($propertyState, (state: PropertyState) =>
  state.entities == null || Object.keys(state.entities).length < 1
    ? null
    : Object.keys(state.entities)
        .map((key) => state.entities[key])
        .find((item) => item.investmentType === ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL),
);

export const $propertyRows = createSelector(
  $propertyList,
  $mortgageList,
  (properties: HTTP_CP_Property[], mortgages: MortgageModel[]): PropertyProductRow[] =>
    properties.map((property) => {
      const mortgage = mortgages != null ? mortgages.find(({ propertyId }) => propertyId === property.id) : null;
      return {
        id: property.id,
        name: property.name,
        property: property,
        valuation: property.roundedCurrentValue,
        latitude: property.latitude,
        longitude: property.longitude,
        investmentType: property.investmentType,
        hasInsurance: false,
        hasMortgage: mortgage != null,
      };
    }),
);

export const $propertyRowsLoading = createSelector($propertiesLoaded, (propertyLoaded: boolean): boolean => !propertyLoaded);

export const $propertyByPreparedNewMortgageCase = createSelector(
  $propertyState,
  selectPreparedNewMortgageCase,
  (state: PropertyState, mortgageCase: any): HTTP_CP_Property =>
    state.entities != null && mortgageCase != null && state.entities[mortgageCase.fkProperty],
);
