import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BouncingBallsModule } from '../basic-components/bouncing-balls/bouncing-balls.module';
import { SafeResourceUrlModule } from '../pipes/safe-resource-url/safe-resource-url.module';
import { SafeStyleModule } from '../pipes/safe-style/safe-style.module';
import { ArrowButtonComponent } from './arrow-button/arrow-button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { DurationInputComponent } from './duration-input/duration-input.component';
import { FileSelectorComponent } from './file-selector/file-selector.component';
import { InputComponent } from './input/input.component';
import { NewDateSelectorComponent } from './new-date-selector/new-date-selector.component';
import { SelectComponent } from './select/select.component';
import { ToggleComponent } from './toggle/toggle.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BouncingBallsModule, SafeResourceUrlModule, SafeStyleModule, OverlayModule],
  declarations: [
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    ToggleComponent,
    ArrowButtonComponent,
    DateSelectorComponent,
    DurationInputComponent,
    FileSelectorComponent,
    NewDateSelectorComponent,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    ToggleComponent,
    ArrowButtonComponent,
    DateSelectorComponent,
    DurationInputComponent,
    FileSelectorComponent,
    NewDateSelectorComponent,
  ],
  providers: [PercentPipe],
})
export class FormComponentsModule {}
