// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_ManualPropertyValueType.proto

export enum ENUM_ManualPropertyValueType {
  MANUAL_PROPERTY_VALUE_TYPE_UNSPECIFIED = 'MANUAL_PROPERTY_VALUE_TYPE_UNSPECIFIED',
  MANUAL_PROPERTY_VALUE_TYPE_PURCHASE_PRICE = 'MANUAL_PROPERTY_VALUE_TYPE_PURCHASE_PRICE',
  MANUAL_PROPERTY_VALUE_TYPE_MORTGAGE_OFFER = 'MANUAL_PROPERTY_VALUE_TYPE_MORTGAGE_OFFER',
  MANUAL_PROPERTY_VALUE_TYPE_LAND_REGISTRY = 'MANUAL_PROPERTY_VALUE_TYPE_LAND_REGISTRY',
  MANUAL_PROPERTY_VALUE_TYPE_INSPECTION = 'MANUAL_PROPERTY_VALUE_TYPE_INSPECTION',
  MANUAL_PROPERTY_VALUE_TYPE_WEBSITE = 'MANUAL_PROPERTY_VALUE_TYPE_WEBSITE',
  MANUAL_PROPERTY_VALUE_TYPE_ADVISER_ESTIMATION = 'MANUAL_PROPERTY_VALUE_TYPE_ADVISER_ESTIMATION',
  MANUAL_PROPERTY_VALUE_TYPE_CLIENT_ESTIMATION = 'MANUAL_PROPERTY_VALUE_TYPE_CLIENT_ESTIMATION',
  MANUAL_PROPERTY_VALUE_TYPE_SPOT_CHECK = 'MANUAL_PROPERTY_VALUE_TYPE_SPOT_CHECK',
  MANUAL_PROPERTY_VALUE_TYPE_SYSTEM_ADJUSTMENT = 'MANUAL_PROPERTY_VALUE_TYPE_SYSTEM_ADJUSTMENT',
  MANUAL_PROPERTY_VALUE_TYPE_LEGACY_UNKNOWN = 'MANUAL_PROPERTY_VALUE_TYPE_LEGACY_UNKNOWN',
  MANUAL_PROPERTY_VALUE_TYPE_LEGACY_AVM = 'MANUAL_PROPERTY_VALUE_TYPE_LEGACY_AVM',
  MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_AGENT_VALUATION = 'MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_AGENT_VALUATION',
  MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_HOME_IMPROVEMENT = 'MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_HOME_IMPROVEMENT',
  MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_SURVEY_VALUATION = 'MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_SURVEY_VALUATION',
  MANUAL_PROPERTY_VALUE_TYPE_LEGACY_SIMPLY_DONT_AGREE = 'MANUAL_PROPERTY_VALUE_TYPE_LEGACY_SIMPLY_DONT_AGREE',
  MANUAL_PROPERTY_VALUE_TYPE_LEGACY_DERIVED_FROM_AVM = 'MANUAL_PROPERTY_VALUE_TYPE_LEGACY_DERIVED_FROM_AVM',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
