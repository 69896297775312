import { HTTP_CP_Mortgage } from '@api-new/mortgageservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  closeMortgageSuccess,
  getMortgages,
  getMortgagesFailure,
  getMortgagesSuccess,
  updateMortgage,
  updateMortgageFailure,
  updateMortgageSuccess,
} from '@shared/store/mortgages/mortgages.actions';
import { MortgagesState } from '@shared/store/mortgages/mortgages.state';

export const getMortgageById = (mortgage: Pick<HTTP_CP_Mortgage, 'id'>): string => mortgage.id;

export const mortgagesAdapter: EntityAdapter<HTTP_CP_Mortgage> = createEntityAdapter<HTTP_CP_Mortgage>({
  selectId: getMortgageById,
});

export const mortgagesInitialState: MortgagesState = mortgagesAdapter.getInitialState({
  loading: true,
  errors: null,
});

const reducer = createReducer(
  mortgagesInitialState,

  on(getMortgages, (state) => ({
    ...state,
    loading: true,
  })),

  on(getMortgagesSuccess, (state, { mortgages }) =>
    mortgagesAdapter.setAll(mortgages ? mortgages : [], {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getMortgagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(updateMortgage, (state) => ({ ...state, loading: true })),

  on(updateMortgageSuccess, (state, { mortgage }) => mortgagesAdapter.upsertOne(mortgage, { ...state, loading: false })),

  on(updateMortgageFailure, (state, { error }) => ({ ...state, loading: false, error })),

  on(closeMortgageSuccess, (state, { mortgageId }) => mortgagesAdapter.removeOne(mortgageId, { ...state, loading: false })),
);

export function mortgagesReducer(state: MortgagesState = mortgagesInitialState, action: Action): MortgagesState {
  return reducer(state, action);
}
