@if (isLoginFromParamsLoading$ | async) {
  <div class="dashly-loading-container">
    <img alt="loading..." src="../../assets/OLD_images/loading.gif" />
  </div>
} @else {
  <div class="landing-container">
    <router-outlet />
    <div class="landing-image">
      <img class="landing-house-image" src="../../assets/OLD_images/onboarding-05.jpg" />
    </div>
  </div>
}
