import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { paths } from '../../platform/paths';
import { AppState } from '../models/app-state.model';
import { $configMaintenanceEnabled } from '../store/selectors/remote-config.selectors';

@Injectable()
export class MaintenanceGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select($configMaintenanceEnabled),
      take(1),
      map((maintenanceEnabled: boolean) => {
        if (maintenanceEnabled) {
          if (state.url.startsWith(`/${paths.MAINTENANCE}`)) {
            return true;
          }
          this.router.navigate([paths.MAINTENANCE]);
          return false;
        }
        return !state.url.startsWith(`/${paths.MAINTENANCE}`);
      }),
    );
  }
}
