import { Routes } from '@angular/router';
import { RedirectToExternalWebsiteComponent } from '@app/OLD_shared/basic-components/redirect-to-external-website/redirect-to-external-website.component';
import { PreloadCheckpoints } from '@app/OLD_shared/enums/preload-checkpoints.enum';
import { AuthGuard } from '@app/OLD_shared/guards/auth.guard';
import { MaintenanceGuard } from '@app/OLD_shared/guards/maintenance.guard';
import { MaintenanceComponent } from '@app/OLD_shared/maintenance/maintenance.component';
import { DeletedComponent } from '@app/auth/deleted/deleted.component';
import { LoginEmailInputComponent } from '@app/auth/login-email-input/login-email-input.component';
import { LoginVerificationCodeComponent } from '@app/auth/login-verification-code/login-verification-code.component';
import { TermsAndConditionsAgreementComponent } from '@app/auth/terms-and-conditions-agreement/terms-and-conditions-agreement.component';
import { paths } from '@platform/paths';
import { environment } from '../environments/environment';
import { AuthComponent } from './auth';

export const routes: Routes = [
  {
    path: paths.PLATFORM,
    canActivate: [AuthGuard, MaintenanceGuard],
    loadChildren: () => import('./platform/platform.module').then((m) => m.PlatformModule),
    data: { preload: true, preloadCheckpoint: PreloadCheckpoints.PLATFORM },
  },
  {
    path: 'maintenance',
    canActivate: [MaintenanceGuard],
    data: { title: 'Maintenance' },
    component: MaintenanceComponent,
  },
  {
    path: 'referral',
    redirectTo: 'auth/register',
  },
  {
    path: paths.AUTH,
    redirectTo: 'auth/login',
  },
  {
    path: 'register',
    redirectTo: 'auth/register',
  },
  {
    path: paths.AUTH,
    canActivate: [MaintenanceGuard],
    component: AuthComponent,
    children: [
      {
        path: 'register',
        data: {
          redirectToExternalWebsiteData: {
            websiteUrl: environment.signUpUrl,
            queryParamsRenameMap: { referralCode: 'referral-code' },
          },
        },
        component: RedirectToExternalWebsiteComponent,
      },
      {
        path: 'delete-account',
        canActivate: [MaintenanceGuard],
        component: DeletedComponent,
      },
      {
        path: 'login',
        canActivate: [MaintenanceGuard],
        component: LoginEmailInputComponent,
      },
      {
        path: 'terms-and-conditions',
        canActivate: [MaintenanceGuard],
        component: TermsAndConditionsAgreementComponent,
      },
      {
        path: 'login-verification',
        canActivate: [MaintenanceGuard],
        component: LoginVerificationCodeComponent,
      },
    ],
  },
  {
    path: paths.UNSUBSCRIBE,
    loadComponent: async () => (await import('./landing/unsubscribe/unsubscribe.component')).UnsubscribeComponent,
  },
  {
    path: paths.AGGREGATOR_LANDING_PAGE,
    canActivate: [AuthGuard, MaintenanceGuard],
    loadComponent: async () => (await import('./aggregator-landing-page/aggregator-landing-page.component')).AggregatorLandingPageComponent,
    loadChildren: () => import('./aggregator-landing-page/aggregator-landing-page.routes').then((r) => r.aggregatorLandingPageRoutes),
  },
  {
    path: '**',
    redirectTo: 'platform',
  },
];
