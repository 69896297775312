import { ENUM_MortgageClosedReason, ENUM_PropertyInvestmentType } from '@api-new/common';
import { HTTP_X_ListLenders_Response_Lender } from '@api-new/mortgageservice';
import { HTTP_CP_Property } from '@api-new/propertyservice';
import { ApplicationTypeEnum } from '@app/OLD_shared/models/mortgage-case.model';
import { createSelector } from '@ngrx/store';
import { AccountsMetadata, MortgageModel } from '../../models/mortgage.model';
import { $propertyList } from '../property/property.selectors';
import { $lenderList } from './lender.selectors';
import { $accountsMetadata, $currentMortgage } from './mortgage.selectors';

export const $currentMortgageProperty = createSelector(
  $currentMortgage,
  $propertyList,
  (mortgage: MortgageModel, properties: HTTP_CP_Property[]): HTTP_CP_Property =>
    properties != null && mortgage != null ? properties.find(({ id }) => id === mortgage.propertyId) : null,
);

export const $currentMortgageLender = createSelector(
  $currentMortgage,
  $lenderList,
  (mortgage: MortgageModel, lenders: HTTP_X_ListLenders_Response_Lender[]): HTTP_X_ListLenders_Response_Lender =>
    lenders != null && mortgage != null ? lenders.find(({ id }) => id === mortgage.lenderId) : null,
);

export const $currentMortgageClosedReasons = createSelector(
  $currentMortgageProperty,
  $currentMortgageLender,
  (property: HTTP_CP_Property, lender: HTTP_X_ListLenders_Response_Lender): { label: string; reason: ENUM_MortgageClosedReason }[] => [
    {
      label: `I have moved home and repaid this mortgage`,
      reason: ENUM_MortgageClosedReason.MORTGAGE_CLOSED_REASON_SOLD_PROPERTY_NEW_MORTGAGE,
    },
    {
      label: `I have moved home and ported this mortgage`,
      reason: ENUM_MortgageClosedReason.MORTGAGE_CLOSED_REASON_SOLD_PROPERTY_OLD_MORTGAGE,
    },
    {
      label: `I have remortgaged to a new deal with ${lender != null && lender.name != null ? lender.name : 'Unknown lender'}`,
      reason: ENUM_MortgageClosedReason.MORTGAGE_CLOSED_REASON_REMORTGAGE_SAME_LENDER,
    },
    {
      label: `I have remortgaged to a new lender`,
      reason: ENUM_MortgageClosedReason.MORTGAGE_CLOSED_REASON_REMORTGAGE_NEW_LENDER,
    },
    {
      label: `I have repaid this mortgage`,
      reason: ENUM_MortgageClosedReason.MORTGAGE_CLOSED_REASON_PAID_OFF,
    },
    ...(property?.investmentType === ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL
      ? [
          {
            label: `I bought new residential property`,
            reason: ENUM_MortgageClosedReason.MORTGAGE_CLOSED_REASON_NEW_PROPERTY_WITH_INVESTMENT,
          },
        ]
      : []),
  ],
);

export const $newMortgageCaseData = createSelector(
  $accountsMetadata,
  $currentMortgage,
  (accountsMetadata: AccountsMetadata, mortgage: MortgageModel): { newMortgageCase: any; newMortgageCaseURLMetadata: AccountsMetadata } => {
    if (accountsMetadata == null || accountsMetadata.dealHash == null) {
      return null;
    }
    return {
      newMortgageCase: {
        fkMortgage: mortgage?.id,
        fkProperty: mortgage?.propertyId,
        type: ApplicationTypeEnum.APP_TYPE_CLICK_THROUGH,
      },
      newMortgageCaseURLMetadata: {
        ...accountsMetadata,
      },
    };
  },
);
