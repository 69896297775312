import { ErrorModel } from '../../models/app-state.model';
import { RemoteConfigModel } from '../../models/remote-config.model';
import { PayloadAction } from '../store-utils';

export class SetRemoteConfigAction extends PayloadAction<RemoteConfigModel> {
  static readonly type = 'SetRemoteConfigAction';
  readonly type = SetRemoteConfigAction.type;
}

export class RemoteConfigErrorAction extends PayloadAction<ErrorModel> {
  static readonly type = 'RemoteConfigErrorAction';
  readonly type = RemoteConfigErrorAction.type;
}

export type RemoteConfigAction = SetRemoteConfigAction | RemoteConfigErrorAction;
