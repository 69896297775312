// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_PropertyType.proto

export enum ENUM_PropertyType {
  PROPERTY_TYPE_UNSPECIFIED = 'PROPERTY_TYPE_UNSPECIFIED',
  PROPERTY_TYPE_DETACHED = 'PROPERTY_TYPE_DETACHED',
  PROPERTY_TYPE_SEMI_DETACHED = 'PROPERTY_TYPE_SEMI_DETACHED',
  PROPERTY_TYPE_TERRACED = 'PROPERTY_TYPE_TERRACED',
  PROPERTY_TYPE_FLAT = 'PROPERTY_TYPE_FLAT',
  PROPERTY_TYPE_MAISONETTE = 'PROPERTY_TYPE_MAISONETTE',
  PROPERTY_TYPE_HALL = 'PROPERTY_TYPE_HALL',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
