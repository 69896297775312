import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppState } from '@app/OLD_shared/models/app-state.model';
import { $configMaintenanceDate } from '@app/OLD_shared/store/selectors/remote-config.selectors';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['maintenance.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceComponent {
  readonly maintenanceDate$: Observable<string> = this.store.pipe(select($configMaintenanceDate));

  constructor(private readonly store: Store<AppState>) {}
}
