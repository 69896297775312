import { Injectable } from '@angular/core';
import {
  HTTP_CP_ClientUserNotificationSettings,
  HTTP_CP_UpdateClientUserNotificationSettings_Request,
  NotificationApiService,
} from '@api-new/notificationservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../models/app-state.model';
import { ToastService } from '../../services/toast.service';
import {
  GetNotificationSettings,
  GetNotificationSettingsFailure,
  GetNotificationSettingsSuccess,
  UpdateNotificationSettings,
  UpdateNotificationSettingsFailure,
  UpdateNotificationSettingsSuccess,
} from './notification-settings.action';

@Injectable()
export class NotificationSettingsEffect {
  getNotificationSettings: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GetNotificationSettings.type),
      switchMap(() =>
        this.notificationApiService.HTTP_CP_GetClientUserNotificationSettings().pipe(
          map((notificationSettings) => new GetNotificationSettingsSuccess(notificationSettings)),
          catchError((error) => of(new GetNotificationSettingsFailure(error))),
        ),
      ),
    ),
  );

  updateNotificationSettings: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateNotificationSettings.type),
      switchMap(({ payload }: { payload: HTTP_CP_ClientUserNotificationSettings }) => {
        const updateNotificationSettingsRequest = {
          ...payload.clientUserNotificationSettings,
        } as HTTP_CP_UpdateClientUserNotificationSettings_Request;
        return this.notificationApiService.HTTP_CP_UpdateClientUserNotificationSettings(updateNotificationSettingsRequest).pipe(
          map((notificationSettings) => {
            this.toastService.showSuccess({
              headLine: 'Success',
              text: 'Notification settings saved successfully',
            });
            return new UpdateNotificationSettingsSuccess(notificationSettings);
          }),
          catchError((error) => of(new UpdateNotificationSettingsFailure(error))),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly notificationApiService: NotificationApiService,
    private readonly toastService: ToastService,
  ) {}
}
