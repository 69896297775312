import { createSelector } from '@ngrx/store';
import { DEFAULT_LOGOUT_TIMEOUT_MINUTES } from '../../common';
import { AppState, RemoteConfigState } from '../../models/app-state.model';
import { RemoteConfigModel } from '../../models/remote-config.model';

export const $remoteConfigState = (state: AppState): RemoteConfigState => state.OLD_remoteConfig;

export const $remoteConfigLoading = createSelector(
  $remoteConfigState,
  (remoteConfigState: RemoteConfigState): boolean => remoteConfigState.loading,
);

export const $remoteConfig = createSelector(
  $remoteConfigState,
  (remoteConfigState: RemoteConfigState): RemoteConfigModel => remoteConfigState.entity || null,
);

export const $configMaintenanceEnabled = createSelector(
  $remoteConfig,
  (remoteConfig: RemoteConfigModel): boolean => (remoteConfig && remoteConfig.maintenanceMode.enabled) || false,
);

export const $configMaintenanceDate = createSelector(
  $remoteConfig,
  (remoteConfig: RemoteConfigModel): string => (remoteConfig && remoteConfig.maintenanceMode.date) || 'soon',
);

export const $configLogoutTimeoutInMilliseconds = createSelector((): number => DEFAULT_LOGOUT_TIMEOUT_MINUTES * 60 * 1000);
