import { Injectable } from '@angular/core';
import { ENUM_MortgagePartOverpaymentType } from '@api-new/common';
import {
  HTTP_CP_CreateMortgage_Request_MortgagePart,
  HTTP_CP_Mortgage,
  HTTP_CP_UpdateMortgageComparisonSettings_Request,
  MortgageApiService,
} from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { getBetterDeals } from '@shared/store/better-deal/better-deal.actions';
import { getMortgages, updateMortgage, updateMortgageFailure, updateMortgageSuccess } from '@shared/store/mortgages/mortgages.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorService } from '../../services/error.service';
import { ToastService } from '../../services/toast.service';
import { SetCurrentMortgageAsEditedAction } from '../actions/mortgage-edit.action';
import {
  CloseMortgageAction,
  CloseMortgageFailureAction,
  CloseMortgageSuccessAction,
  CreateMortgageAction,
  CreateMortgageFailureAction,
  CreateMortgageSuccessAction,
  GetClientUserDataAction,
  GetClientUserDataSuccessAction,
  GetMortgagesAction,
  GetMortgagesFailureAction,
  GetMortgagesSuccessAction,
  GetSingleMortgageSuccessAction,
  IncrementSearchCriteriaSelectedIndexAction,
  RefreshSearchAction,
  UpdateMortgageAction,
  UpdateMortgageFailureAction,
  UpdateMortgageSuccessAction,
  UpdateSearchCriteriaAction,
  UpdateSearchCriteriaFailureAction,
  UpdateSearchCriteriaMetadataAction,
  UpdateSearchCriteriaSelectedIndexAction,
  UpdateSearchCriteriaSuccessAction,
} from '../actions/mortgage.action';
import { closeMortgageCaseSuccess } from '../mortgage-case/mortgage-case.action';

@Injectable()
export class MortgageEffect {
  getMortgages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMortgagesAction.type),
      switchMap(() =>
        this.mortgageApiService.HTTP_CP_ListMortgages().pipe(
          map(({ mortgages }) => new GetMortgagesSuccessAction(mortgages != null ? mortgages : [])),
          catchError((error) => {
            this.errorService.pushError(error);
            return of(new GetMortgagesFailureAction(error));
          }),
        ),
      ),
    ),
  );

  getClientUserData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GetClientUserDataAction.type),
      switchMap(() =>
        this.mortgageApiService.HTTP_CP_ListSmartSearches().pipe(
          map(({ smartSearches }) => new GetClientUserDataSuccessAction(smartSearches ? smartSearches : [])),
          catchError((error) => {
            this.errorService.pushError(error);
            return of(new GetMortgagesFailureAction(error));
          }),
        ),
      ),
    ),
  );

  createMortgage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateMortgageAction.type),
      map((action: CreateMortgageAction) => action.payload),
      switchMap((mortgage) => {
        return this.mortgageApiService
          .HTTP_CP_CreateMortgage({
            mortgageType: mortgage.mortgageType,
            propertyId: mortgage.propertyId.toString(),
            lenderId: mortgage.lenderId.toString(),
            mortgageParts: mortgage.mortgageParts.map(
              (mortgagePart) =>
                ({
                  mortgagePartSteps: mortgagePart.mortgagePartSteps,
                  initialAmount: mortgagePart.initialAmount,
                  repaymentType: mortgagePart.repaymentType,
                  mortgagePartOverpayments: mortgagePart.overpayments.map((overpayment) => ({
                    amount: overpayment.amount,
                    mortgagePartOverpaymentType:
                      overpayment.mortgagePartOverpaymentType === ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_RECURRING
                        ? ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_RECURRING
                        : ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_ONE_TIME,
                    startDate: overpayment.startDate,
                    endDate: overpayment.endDate,
                  })),
                }) as HTTP_CP_CreateMortgage_Request_MortgagePart,
            ),
            mortgageComparisonSettings: {
              savingMethod: mortgage.mortgageSearch.savingMethod,
              preferredComparisonPeriod: mortgage.mortgageSearch.comparisonPeriod,
            },
            closeExistingMortgage: null,
          })
          .pipe(
            map((newMortgage: HTTP_CP_Mortgage) => {
              return new CreateMortgageSuccessAction(newMortgage);
            }),
            catchError((error) => {
              this.toastService.showError({ headLine: 'Create', text: 'Mortgage failed to created' });
              return of(new CreateMortgageFailureAction(error));
            }),
          );
      }),
    ),
  );

  updateMortgage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateMortgageAction.type),
      map((action: UpdateMortgageAction) => action.payload),
      tap((mortgage) => updateMortgage({ mortgage })),
      switchMap((mortgage) =>
        this.mortgageApiService.HTTP_CP_UpdateMortgage(mortgage).pipe(
          switchMap((updatedMortgage) => {
            this.toastService.showSuccess({
              headLine: 'Mortgage update success',
              text: 'Your mortgage details have been successfully updated',
            });
            return [
              new UpdateMortgageSuccessAction(updatedMortgage),
              new SetCurrentMortgageAsEditedAction(),
              updateMortgageSuccess({ mortgage: updatedMortgage }),
            ];
          }),
          catchError((error) => {
            this.toastService.showError({
              headLine: 'Mortgage update failed',
              text: 'Please recheck the part and step terms entered.',
            });
            return [new UpdateMortgageFailureAction(error), updateMortgageFailure(error)];
          }),
        ),
      ),
    ),
  );

  closeMortgage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CloseMortgageAction.type),
      map((action: CloseMortgageAction) => action.payload),
      switchMap((closingModel) =>
        this.mortgageApiService
          .HTTP_CP_CloseMortgage({
            mortgageId: closingModel.id,
            reason: closingModel.reason,
            when: closingModel.when.toString(),
          })
          .pipe(
            map(() => {
              this.toastService.showSuccess({ headLine: 'Close', text: 'Mortgage was successfully closed' });
              return new CloseMortgageSuccessAction(closingModel);
            }),
            catchError((error) => {
              this.toastService.showError({ headLine: 'Close', text: 'Mortgage failed to close' });
              return of(new CloseMortgageFailureAction(error));
            }),
          ),
      ),
    ),
  );

  handleCloseMortgageSuccessAction = createEffect(() =>
    this.actions$.pipe(
      ofType(CloseMortgageSuccessAction.type),
      map(() => getMortgages()),
    ),
  );

  updateSearchCriteria$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateSearchCriteriaAction.type),
      map((action: UpdateSearchCriteriaAction) => action.payload),
      switchMap((comparisonSettingsRequest: HTTP_CP_UpdateMortgageComparisonSettings_Request) =>
        this.mortgageApiService.HTTP_CP_UpdateMortgageComparisonSettings(comparisonSettingsRequest).pipe(
          map((newSearch) => {
            this.toastService.showSuccess({
              headLine: 'Update',
              text: 'Search preferences were successfully updated',
            });
            return new UpdateSearchCriteriaSuccessAction(newSearch);
          }),
          catchError((error) => {
            this.toastService.showError({ headLine: 'Update', text: 'Search preferences failed to update' });
            return of(new UpdateSearchCriteriaFailureAction(error));
          }),
        ),
      ),
    ),
  );

  refreshSearchAfterSearchCriteriaUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateSearchCriteriaSuccessAction.type),
      map((action: UpdateSearchCriteriaSuccessAction) => new RefreshSearchAction(action.payload.mortgageId)),
    ),
  );

  refreshSearchAfterMortgageGet$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSingleMortgageSuccessAction.type),
      map((action: GetSingleMortgageSuccessAction) => new RefreshSearchAction(action.payload.id)),
    ),
  );

  refreshMortgageDataAfterUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateMortgageSuccessAction.type),
      map((action: UpdateMortgageSuccessAction) => new RefreshSearchAction(action.payload.id)),
    ),
  );

  refreshSearchAfterApplicationCancel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(closeMortgageCaseSuccess),
      map(({ mortgageCaseId }) => new RefreshSearchAction(mortgageCaseId)),
    ),
  );

  refreshSearchAfterMortgageCreation: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateMortgageSuccessAction.type),
      map((action: CreateMortgageSuccessAction) => new RefreshSearchAction(action.payload.id)),
    ),
  );

  updateSearchCriteriaMetadata$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateSearchCriteriaSelectedIndexAction.type, IncrementSearchCriteriaSelectedIndexAction.type),
      map(() => new UpdateSearchCriteriaMetadataAction()),
    ),
  );

  dispatchLEGACYGetMortgagesAfterCreateMortgageSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateMortgageSuccessAction.type),
      map(() => new GetMortgagesAction()),
    ),
  );

  dispatchGetMortgagesAfterCreateMortgageSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateMortgageSuccessAction.type),
      map(() => getMortgages()),
    ),
  );

  dispatchGetBestDealsAfterCreateMortgageSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateMortgageSuccessAction.type),
      map((action: CreateMortgageSuccessAction) => {
        return getBetterDeals({ mortgageId: action.payload.id });
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly errorService: ErrorService,
    private readonly toastService: ToastService,
    private readonly mortgageApiService: MortgageApiService,
  ) {}
}
