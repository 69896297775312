import { AuthEffect } from '@shared/store/auth/auth.effect';
import { AffiliateEffect } from '../affiliate/affiliate.effect';
import { CompanyEffect } from '../company/company.effect';
import { MortgageCaseEffect } from '../mortgage-case/mortgage-case.effect';
import { NotificationSettingsEffect } from '../notification-settings/notification-settings.effect';
import { PropertyEffect } from '../property/property.effect';
import { CalendarEventEffect } from './calendar-event.effect';
import { FullstoryEffect } from './fullstory.effect';
import { HelpdeskEffect } from './helpdesk.effect';
import { LenderEffect } from './lender.effect';
import { MortgageEditEffect } from './mortgage-edit.effect';
import { MortgageEffect } from './mortgage.effect';
import { RemoteConfigEffect } from './remote-config.effect';
import { SavingChartEffect } from './saving-chart.effect';

export const OLD_effects = [
  AffiliateEffect,
  AuthEffect,
  CalendarEventEffect,
  CompanyEffect,
  FullstoryEffect,
  HelpdeskEffect,
  LenderEffect,
  MortgageCaseEffect,
  MortgageEditEffect,
  MortgageEffect,
  NotificationSettingsEffect,
  PropertyEffect,
  RemoteConfigEffect,
  SavingChartEffect,
];
