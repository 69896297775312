import { HTTP_CP_MortgageCase } from '@api-new/caseservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { MortgageCaseState } from '../../models/app-state.model';
import {
  closeMortgageCase,
  closeMortgageCaseFailure,
  closeMortgageCaseSuccess,
  createMortgageCase,
  createMortgageCaseFailure,
  createMortgageCaseSuccess,
  getMortgageCasesFailure,
  getMortgageCasesSuccess,
  getOldMortgageCases,
  updateNewMortgageCase,
} from './mortgage-case.action';

export const getMortgageCaseById = (mortgageCase: Pick<HTTP_CP_MortgageCase, 'id'>): string => mortgageCase.id;

export const mortgageCaseAdapter: EntityAdapter<HTTP_CP_MortgageCase> = createEntityAdapter<HTTP_CP_MortgageCase>({
  selectId: getMortgageCaseById,
});

export const mortgageCaseInitialState: MortgageCaseState = {
  data: mortgageCaseAdapter.getInitialState(),
  errors: null,
  loading: false,
  loaded: false,
  metadata: {
    taskUpdateLoading: false,
    createMortgageCaseLoading: false,
    newMortgageCaseURLMetadata: null,
    newMortgageCase: null,
  },
};

const reducer = createReducer(
  mortgageCaseInitialState,

  on(getOldMortgageCases, (state) => ({
    ...state,
    loading: !state.loaded,
  })),

  on(closeMortgageCase, (state) => ({
    ...state,
    loading: true,
  })),

  on(createMortgageCase, (state) => ({
    ...state,
    loading: true,
    metadata: {
      ...state.metadata,
      createMortgageCaseLoading: true,
    },
  })),

  on(createMortgageCaseFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
    metadata: {
      ...state.metadata,
      createMortgageCaseLoading: false,
    },
  })),

  on(getMortgageCasesFailure, closeMortgageCaseFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(getMortgageCasesSuccess, (state, { mortgageCases }) => ({
    ...state,
    data: mortgageCaseAdapter.addMany(mortgageCases, state.data),
    loading: false,
    loaded: true,
  })),

  on(createMortgageCaseSuccess, (state) => ({
    ...state,
    loading: false,
  })),

  on(closeMortgageCaseSuccess, (state, { mortgageCaseId }) => ({
    ...state,
    data: mortgageCaseAdapter.removeOne(mortgageCaseId, state.data),
    loading: false,
  })),

  on(updateNewMortgageCase, (state, newMortgageCaseMetadata) => ({
    ...state,
    metadata: {
      ...state.metadata,
      ...newMortgageCaseMetadata,
    },
  })),
);

export function mortgageCaseReducer(state: MortgageCaseState = mortgageCaseInitialState, action: Action): MortgageCaseState {
  return reducer(state, action);
}
